import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { blog } from "../../api";
import { IBlogContent, IBlogs } from "../../interface/blogs.interface";
import ReactMarkdown from "react-markdown";
import moment from "moment";

export function BlogPage() {
  const { slug } = useParams();
  const [blogContent, setBlogContent] = useState<IBlogContent | undefined>();
  const [listBlog, setListBlog] = useState<IBlogs[]>([]);

  useEffect(() => {
    blog.getBlogBySlug(slug || "").then((res) => {
      setBlogContent(res?.data?.data);
    });
  }, [slug]);

  useEffect(() => {
    blog.getAllBlog().then((res) => {
      setListBlog(res?.data?.data);
    });
  }, []);

  return (
    <div className="main blog mt-4">
      <div className="row row-small">
        <div className="col-lg-9 large-9  small-12">
          <header className="entry-header pb-4">
            <div className="entry-header-text entry-header-text-top text-left">
              <h1 className="entry-title fw-bold">{blogContent?.title}</h1>
              <div className="entry-divider is-divider small"></div>
              <div className="entry-meta uppercase is-xsmall">
                <span className="posted-on">Lượt xem {blogContent?.view}</span>
              </div>
            </div>
          </header>
          <section className="pt-4">
            <ReactMarkdown>{blogContent?.content}</ReactMarkdown>
          </section>
        </div>
        <div className="col-lg-3 large-3 small-12">
          <span className="widget-title ">
            <h4>Bài viết mới</h4>
          </span>
          <div className="is-divider small"></div>
          <div className="">
            {listBlog?.map((item) => (
              <Link key={item._id} to={item.slug}>
                <div className="box box-vertical box-text-bottom box-blog-post has-hover">
                  <div
                    className="box-image  align-items-center"
                    style={{ width: "25%" }}
                  >
                    <div className="image-cover">
                      <img
                        className="attachment-original size-original wp-post-image"
                        src={item.thumbnailImgUrl}
                        alt="hoa sen vanguoi dep"
                      />{" "}
                    </div>
                  </div>
                  <div className="box-text text-left is-small">
                    <div className="box-text-inner blog-post-inner">
                      <h5 className="post-title is-large ">{item.title}</h5>
                      <div className="post-meta is-small op-8">
                        {moment(item.createdDate).format("DD/MM/YYYY")}
                      </div>{" "}
                      <div className="is-divider"></div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
