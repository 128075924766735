import React, { useState } from "react";
import { LOCAL_STORAGE_KEY } from "../../constants/general.constant";
import { formatPrice } from "../../helper/utils";
import { IOrderLocal } from "../../interface/product.interface";

/* eslint-disable jsx-a11y/anchor-is-valid */
interface IPropsPayment {
  getNumberOrder: (o: number) => void;
  setIsCheckout: (o: boolean) => void;
}

const Payment = (props: IPropsPayment) => {
  const { getNumberOrder, setIsCheckout } = props;
  const [orders, setOrders] = useState<IOrderLocal[]>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.ORDERS) || "[]")
  );

  const decreaseQuantity = (id: string) => {
    const tmp = [...orders];
    for (var i = 0; i < orders.length; i++) {
      if (orders[i].product === id) {
        if (orders[i].quantity < 2) break;
        orders[i].quantity--;
        break;
      }
    }
    localStorage.setItem(LOCAL_STORAGE_KEY.ORDERS, JSON.stringify(tmp));
    setOrders(tmp);
  };

  const increaseQuantity = (id: string) => {
    const tmp = [...orders];
    for (var i = 0; i < orders.length; i++) {
      if (orders[i].product === id) {
        orders[i].quantity++;
        break;
      }
    }
    localStorage.setItem(LOCAL_STORAGE_KEY.ORDERS, JSON.stringify(tmp));
    setOrders(tmp);
  };

  const deleteProduct = (id: string) => {
    const updatedOrders = orders.filter(
      (order: IOrderLocal) => order.product !== id
    );

    setOrders(updatedOrders);
    localStorage.setItem(
      LOCAL_STORAGE_KEY.ORDERS,
      JSON.stringify(updatedOrders)
    );
    getNumberOrder(updatedOrders.length);
  };

  return (
    <>
      <div className="cart-container container page-wrapper page-checkout">
        <div className="woocommerce">
          <div className="woocommerce row row-large row-divided m-auto">
            <div className="col large-7 ps-0">
              <form
                className="woocommerce-cart-form"
                action="https://shophoahanoi.com/cart/"
                method="post"
              >
                <div className="cart-wrapper sm-touch-scroll">
                  <table
                    className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th className="product-name" colSpan={3}>
                          Sản phẩm
                        </th>
                        <th className="product-price">Giá</th>
                        <th className="product-quantity">Số lượng</th>
                        <th className="product-subtotal">Tạm tính</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((item: IOrderLocal) => (
                        <tr
                          key={item.product}
                          className="woocommerce-cart-form__cart-item cart_item"
                        >
                          <td className="product-remove">
                            <a
                              className="remove"
                              aria-label="Xóa sản phẩm này"
                              onClick={() => deleteProduct(item.product)}
                            >
                              ×
                            </a>{" "}
                          </td>

                          <td className="product-thumbnail">
                            <a href={`/chi-tiet/${item.product}`}>
                              <img
                                width="247"
                                height="296"
                                src={item.image}
                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail lazy-load-active"
                                alt="Cart"
                                decoding="async"
                                title="Cart"
                              />
                            </a>{" "}
                          </td>

                          <td className="product-name" data-title="Sản phẩm">
                            <a href={`/chi-tiet/${item.product}`}>
                              {item.name}
                            </a>{" "}
                            <div className="show-for-small mobile-product-price">
                              <span className="mobile-product-price__qty">
                                {item.quantity} x{" "}
                              </span>
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₫
                                  </span>
                                  &nbsp;{formatPrice(item.price)}
                                </bdi>
                              </span>{" "}
                            </div>
                          </td>

                          <td className="product-price" data-title="Giá">
                            <span className="woocommerce-Price-amount amount">
                              <bdi>
                                <span className="woocommerce-Price-currencySymbol">
                                  ₫
                                </span>
                                &nbsp;{formatPrice(item.price)}
                              </bdi>
                            </span>{" "}
                          </td>

                          <td
                            className="product-quantity"
                            data-title="Số lượng"
                          >
                            <div className="quantity buttons_added">
                              <input
                                type="button"
                                value="-"
                                className="minus button is-form"
                                onClick={() => decreaseQuantity(item.product)}
                              />{" "}
                              <input
                                type="number"
                                id="quantity_64e632d11ab4b"
                                className="input-text qty text"
                                step="1"
                                value={item.quantity}
                                size={4}
                                placeholder=""
                              />
                              <input
                                type="button"
                                value="+"
                                className="plus button is-form"
                                onClick={() => increaseQuantity(item.product)}
                              />{" "}
                            </div>
                          </td>

                          <td
                            className="product-subtotal"
                            data-title="Tạm tính"
                          >
                            <span className="woocommerce-Price-amount amount">
                              <bdi>
                                <span className="woocommerce-Price-currencySymbol">
                                  ₫
                                </span>
                                &nbsp;
                                {formatPrice(item.price * item.quantity)}
                              </bdi>
                            </span>{" "}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td colSpan={6} className="actions clear border-0">
                          <div className="continue-shopping pull-left text-left">
                            <a
                              className="button-continue-shopping button primary is-outline"
                              href="/"
                            >
                              ←&nbsp;Tiếp tục xem sản phẩm{" "}
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>

            <div className="cart-collaterals large-5 ps-0">
              <div className="cart-sidebar col-inner ">
                <div className="cart_totals calculated_shipping">
                  <table cellSpacing={0}>
                    <thead>
                      <tr>
                        <th
                          className="product-name"
                          colSpan={2}
                          style={{ borderWidth: 3 }}
                        >
                          Cộng giỏ hàng
                        </th>
                      </tr>
                    </thead>
                  </table>

                  <h2>Cộng giỏ hàng</h2>

                  <table
                    cellSpacing={0}
                    className="shop_table shop_table_responsive"
                  >
                    <tbody>
                      <tr className="order-total">
                        <th>Tổng</th>
                        <td data-title="Tổng">
                          <strong>
                            <span className="woocommerce-Price-amount amount">
                              <bdi>
                                <span className="woocommerce-Price-currencySymbol">
                                  ₫
                                </span>
                                &nbsp;
                                {formatPrice(
                                  orders.reduce(
                                    (acc, crr) =>
                                      acc + crr.quantity * crr.price,
                                    0
                                  )
                                )}
                              </bdi>
                            </span>
                          </strong>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="wc-proceed-to-checkout">
                    <button
                      className="checkout-button button alt wc-forward"
                      onClick={() => setIsCheckout(true)}
                      disabled={orders.length === 0}
                    >
                      Tiến hành thanh toán
                    </button>
                  </div>
                </div>
                <form className="checkout_coupon mb-0">
                  <div className="coupon">
                    <h3 className="widget-title">
                      <i className="bi bi-tag-fill"></i> Phiếu ưu đãi
                    </h3>
                    <input
                      type="text"
                      name="coupon_code"
                      className="input-text"
                      id="coupon_code"
                      placeholder="Mã ưu đãi"
                    />{" "}
                    <input
                      type="submit"
                      className="is-form expand"
                      name="apply_coupon"
                    />
                  </div>
                </form>
                <div className="cart-sidebar-content relative"></div>{" "}
              </div>
            </div>
          </div>

          <div className="cart-footer-content after-cart-content relative"></div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Payment);
