import API from "./config.api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getListCategory() {
    return API.get(`categories?site=shophoadamtang`);
  },

  getListCategoryById(id: string) {
    return API.get(`categories/${id}`);
  },

  createOrder(order: any) {
    return API.post("orders", order);
  },

  getAllProduct(priceFrom: number, priceTo: number) {
    let queryParams = "site=shophoadamtang"
    if (priceFrom) queryParams += `&priceFrom=${priceFrom}`
    if (priceTo) queryParams += `&priceTo=${priceTo}`
    return API.get(`products?${queryParams}`);
  },

  getProductById(id: string) {
    return API.get(`products/${id}`);
  },

  getFavoriteProduct() {
    return API.get(`products/favorite?site=shophoadamtang`);
  },

  getRelatedProduct(id: string) {
    return API.get(`products/${id}/related`);
  },
};
