import { useEffect, useState } from "react";
import CategoryContent from "./CategoryContent";
import { ICategory, IProduct } from "../../interface/product.interface";
import { product } from "../../api";
import { useParams } from "react-router-dom";
import { CATEGORY_INIT } from "../../mocks/product";

export default function CategoryProduct() {
  const { id } = useParams();

  const [defaultProductList, setDefaultProductList] = useState<IProduct[]>([]);
  const [productList, setProductList] = useState<IProduct[]>([]);

  const [category, setCategory] = useState<ICategory>(CATEGORY_INIT);
  const [sortCondition, setSortCondition] = useState("menu_order");

  useEffect(() => {
    if (!id) return;
    product.getListCategoryById(id).then((res) => {
      setCategory(res?.data?.data);
      setProductList(res?.data?.data?.products);
      setDefaultProductList(res?.data?.data?.products);
    });
  }, [id]);

  useEffect(() => {
    processSort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProductList, sortCondition]);

  const processSort = (sortCondition?: string) => {
    if (sortCondition === "menu_order") {
      setProductList(
        defaultProductList.sort((a, b) => a._id.localeCompare(b._id))
      );
    }
    if (sortCondition === "date") {
      setProductList(
        defaultProductList.sort((a, b) => b._id.localeCompare(a._id))
      );
    }
    if (sortCondition === "price") {
      setProductList(
        defaultProductList.sort((a, b) => Number(a.price) - Number(b.price))
      );
    }
    if (sortCondition === "price-desc") {
      setProductList(
        defaultProductList.sort((a, b) => Number(b.price) - Number(a.price))
      );
    }
  };

  const handleSort = (e: any) => {
    const sortCondition = e.target.value;
    setSortCondition(sortCondition);
    processSort(sortCondition);
  };


  return (
    <>
      <div className="shop-page-title category-page-title page-title ">
        <div className="page-title-inner d-flex medium-flex-wrap container">
          <div className="flex-col flex-grow medium-text-center">
            <h1 className="shop-page-title is-xlarge">{category.name}</h1>
            <div className="is-small">
              <nav className="woocommerce-breadcrumb breadcrumbs uppercase">
                <a href="/">Trang chủ</a> <span className="divider">/</span>{" "}
                {category.name}
              </nav>
            </div>
            <div className="category-filtering category-filter-row show-for-medium">
              <span className="filter-button uppercase plain">
                <strong>Lọc</strong>
              </span>
              <div className="inline-block"></div>
            </div>
          </div>
          <div className="flex-col medium-text-center">
            <p className="woocommerce-result-count hide-for-medium">
              Hiển thị kết quả duy nhất
            </p>
            <form className="woocommerce-ordering">
              <select
                name="orderby"
                className="orderby"
                aria-label="Đơn hàng của cửa hàng"
                onChange={handleSort}
              >
                <option value="menu_order">Thứ tự mặc định</option>
                <option value="date">Mới nhất</option>
                <option value="price">Thứ tự theo giá: thấp đến cao</option>
                <option value="price-desc">
                  Thứ tự theo giá: cao xuống thấp
                </option>
              </select>
            </form>
          </div>
        </div>
      </div>

      <CategoryContent productList={productList} />
    </>
  );
}
