import Slider from "react-slick";
import banner1 from "../assets/images/Banner1.png";

export default function SliderCoffee() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
  };

  return (
    <section className="slider-container">
      <div className="container-fluid container position-relative p-0">
        <Slider {...settings} className="slider">
          <div>
            <img src={banner1} alt="banner 1" />
          </div>
        </Slider>
      </div>
    </section>
  );
}
