export default function Footer() {
  return (
    <>
      <footer id="footer" className="footer-wrapper">
        <section className="section footer-section dark" id="section_489219579">
          <div className="bg section-bg fill bg-fill bg-loaded"></div>

          <div className="section-content relative">
            <div className="row row-small" id="row-1845467591">
              <div id="col-1960001888" className="col-md-3 col-sm-12 col-lg-3">
                <div className="col-inner">
                  <p>
                    <span style={{ fontSize: "80%" }}>
                      <strong>Dịch vụ shophoadamtang.com</strong>
                    </span>
                    <br></br>
                    <span style={{ fontSize: "80%" }}>
                      <em>
                        Chuyên cung cấp các loại Hoa Đám Tang, Hoa Viếng, Hoa
                        Chia Buồn, Vòng Hoa Tang Lễ, Giỏ Trái Cây… Nhận phúng
                        điếu thay khách hàng…với nhiều mẫu hoa Lan, Hoa Cúc, Hoa
                        Đồng Tiền, Hoa Hồng….Shop hân hạnh được phụ vụ quý khách
                        chu đáo và an tâm nhất
                      </em>
                    </span>
                  </p>
                  <div
                    className="img has-hover x md-x lg-x y md-y lg-y"
                    id="image_1340450623"
                  >
                    <div className="img-inner dark"></div>
                  </div>
                </div>
              </div>

              <div id="col-2110780154" className="col-md-3 col-sm-12 col-lg-3">
                <div className="col-inner">
                  <div className="ux-menu stack stack-col justify-start ux-menu--divider-solid">
                    <div className="ux-menu-title flex">HƯỚNG DẪN </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Quy định chuyển phát{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Chính sách đổi/trả{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Hướng dẫn mua hàng{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Hướng dẫn thanh toán{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Quy định bảo mật{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div id="col-1193165396" className="col-md-3 col-sm-12 col-lg-3">
                <div className="col-inner">
                  <div className="ux-menu stack stack-col justify-start ux-menu--divider-solid">
                    <div className="ux-menu-title flex">
                      CHĂM SÓC KHÁCH HÀNG{" "}
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Quy định chuyển phát{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Chính sách đổi/trả{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Hướng dẫn mua hàng{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Hướng dẫn thanh toán{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Quy định bảo mật{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div id="col-488305244" className="col-md-3 col-sm-12 col-lg-3">
                <div className="col-inner">
                  <div className="ux-menu stack stack-col justify-start ux-menu--divider-solid">
                    <div className="ux-menu-title flex">
                      THỜI GIAN LÀM VIỆC{" "}
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Quy định chuyển phát{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Chính sách đổi/trả{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Hướng dẫn mua hàng{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Hướng dẫn thanh toán{" "}
                        </span>
                      </a>
                    </div>

                    <div className="ux-menu-link flex menu-item">
                      <a className="ux-menu-link__link flex" href="/">
                        <i className="ux-menu-link__icon text-center bi bi-chevron-right"></i>{" "}
                        <span className="ux-menu-link__text">
                          Quy định bảo mật{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <a
          href="#top"
          className="back-to-top button icon invert plain fixed bottom z-1 is-outline hide-for-medium circle active"
          id="top-link"
          aria-label="Go to top"
        >
          <i className="icon-angle-up"></i>
        </a> */}
      </footer>
    </>
  );
}
