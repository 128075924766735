/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Payment from "./Payment";
import CheckoutForm from "./CheckoutForm";
import { ToastContainer } from "react-toastify";
import { LOCAL_STORAGE_KEY } from "../../constants/general.constant";
interface IPropsOrderPage {
  getNumberOrder: (o: number) => void;
}

const OrderPage = ({ getNumberOrder }: IPropsOrderPage) => {
  const [isCheckout, setIsCheckout] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [numberOrder, setNumberOrder] = useState(0);

  useEffect(() => {
    const orders = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY.ORDERS) || "[]"
    );
    setNumberOrder(orders.length);
  }, [getNumberOrder]);

  const handleCompleteOrder = () => {
    setIsComplete(true);
    getNumberOrder(0);
  };

  return (
    <>
      <main id="main" className="">
        <div className="checkout-page-title page-title">
          <div className="page-title-inner flex-row medium-flex-wrap container">
            <div className="flex-grow medium-text-center">
              <nav className="breadcrumbs flex-row flex-row-center heading-font checkout-breadcrumbs text-center strong h2 uppercase">
                <a
                  className={`hide-for-small ${!isCheckout && "current"}`}
                  onClick={() => {
                    setIsCheckout(false);
                    setIsComplete(false);
                  }}
                >
                  Shopping Cart{" "}
                </a>

                <span className="divider hide-for-small">
                  <i className="bi bi-chevron-right"></i>
                </span>

                <a
                  className={`hide-for-small ${
                    isCheckout && !isComplete && "current"
                  }`}
                  onClick={() => {
                    if (numberOrder === 0) return;
                    setIsCheckout(true);
                    setIsComplete(false);
                  }}
                >
                  Checkout details{" "}
                </a>

                <span className="divider hide-for-small">
                  <i className="bi bi-chevron-right"></i>
                </span>

                <a
                  className={`no-click hide-for-small ${
                    isCheckout && isComplete && "current"
                  }`}
                >
                  Order Complete{" "}
                </a>
              </nav>
            </div>
          </div>
        </div>

        {!isCheckout && (
          <Payment
            getNumberOrder={getNumberOrder}
            setIsCheckout={setIsCheckout}
          />
        )}
        {isCheckout && !isComplete && (
          <CheckoutForm handleCompleteOrder={handleCompleteOrder} />
        )}
        {isCheckout && isComplete && (
          <div className="cart-container container page-wrapper page-checkout flex flex-row-col text-center">
            <div
              className="continue-shopping flex-center uppercase"
              style={{ fontSize: "1.6rem", margin: "2em" }}
            >
              Đặt hàng thành công
            </div>
            <div className="woocommerce flex">
              <div className="continue-shopping pull-left ">
                <a
                  className="button-continue-shopping button primary is-outline"
                  href="/"
                >
                  ←&nbsp;Tiếp tục xem sản phẩm{" "}
                </a>
              </div>
            </div>
          </div>
        )}
      </main>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default React.memo(OrderPage);
