import { Link } from "react-router-dom";
import { formatPrice } from "../../helper/utils";
import { IProduct } from "../../interface/product.interface";
import { useEffect, useState } from "react";
import { product } from "../../api";
import BlockWidget from "../../components/BlockWidget";

interface IPropsCategoryContent {
  productList: IProduct[];
}

export default function CategoryContent({
  productList,
}: IPropsCategoryContent) {
  const [favoriteProduct, setFavoriteProduct] = useState<IProduct[]>([]);

  useEffect(() => {
    product.getFavoriteProduct().then((res) => {
      setFavoriteProduct(res?.data?.data);
    });
  }, []);

  return (
    <main id="main" className="archive">
      <div className="row category-page-row m-auto">
        <div className="col large-3 hide-for-medium">
          <div id="shop-sidebar" className="sidebar-inner col-inner">
            <BlockWidget />
            <aside
              id="woocommerce_products-2"
              className="widget woocommerce widget_products"
            >
              <span className="widget-title shop-sidebar">
                Sản phẩm yêu thích
              </span>
              <div className="is-divider small"></div>
              <ul className="product_list_widget ps-0">
                {favoriteProduct.map((item) => (
                  <li key={item._id}>
                    <a href={`/chi-tiet/${item._id}`}>
                      <img
                        className="attachment-woocommerce_gallery_thumbnail size-woocommerce_gallery_thumbnail lazy-load-active"
                        width="100"
                        height="100"
                        src={item.imageUrl}
                        alt={item.name}
                        decoding="async"
                        loading="lazy"
                        sizes="(max-width: 100px) 100vw, 100px"
                      />{" "}
                      <span className="product-title">{item.name}</span>
                    </a>

                    <div className="price-wrapper">
                      {item?.needContact ? (
                        <span
                          className="price"
                          style={{ color: "red", fontWeight: 700 }}
                        >
                          Giá: Liên Hệ
                        </span>
                      ) : (
                        <span className="price d-flex">
                          {item?.compareAtPrice ? (
                            <del aria-hidden="true">
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₫
                                  </span>
                                  &nbsp;{formatPrice(item?.compareAtPrice)}
                                </bdi>
                              </span>
                            </del>
                          ) : (
                            ""
                          )}
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              <span className="woocommerce-Price-currencySymbol">
                                ₫
                              </span>
                              &nbsp;{formatPrice(item?.price)}
                            </bdi>
                          </span>
                        </span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </aside>{" "}
          </div>
        </div>

        <div className="large-9">
          <div className="shop-container">
            <div className="woocommerce-notices-wrapper"></div>
            <div className="products row row-small large-columns-4 medium-columns-3 small-columns-2 has-equal-box-heights">
              {productList.map((item) => (
                <div
                  key={item._id}
                  className="product-small col has-hover product type-product post-2523 status-publish first instock product_cat-hoa-bo-dep product_cat-chua-phan-loai has-post-thumbnail sale featured shipping-taxable purchasable product-type-simple"
                >
                  <Link to={`/chi-tiet/${item._id}`}>
                    <div className="col-inner">
                      <div className="badge-container absolute left top z-1">
                        <div className="callout badge badge-square">
                          {/* <div className="badge-inner secondary on-sale">
                        <span className="onsale">-3%</span>
                      </div> */}
                        </div>
                      </div>
                      <div
                        className="product-small box "
                        style={{ overflow: "hidden" }}
                      >
                        <div className="box-image">
                          <div className="image-zoom">
                            <img
                              width="247"
                              height="296"
                              src={item.imageUrl}
                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail lazy-load-active"
                              alt=""
                              decoding="async"
                              loading="lazy"
                            />{" "}
                          </div>
                        </div>

                        <div className="box-text box-text-products text-center grid-style-2">
                          <div className="title-wrapper">
                            <p className="name product-title woocommerce-loop-product__title">
                              {item.name}
                            </p>
                          </div>
                          <div className="price-wrapper">
                            {item?.needContact ? (
                              <span
                                className="price"
                                style={{ color: "red", fontWeight: 700 }}
                              >
                                Giá: Liên Hệ
                              </span>
                            ) : (
                              <span className="price d-flex justify-content-center">
                                {item?.compareAtPrice ? (
                                  <del aria-hidden="true">
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          ₫
                                        </span>
                                        &nbsp;
                                        {formatPrice(item?.compareAtPrice)}
                                      </bdi>
                                    </span>
                                  </del>
                                ) : (
                                  ""
                                )}
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      ₫
                                    </span>
                                    &nbsp;{formatPrice(item?.price)}
                                  </bdi>
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>{" "}
        </div>
      </div>
    </main>
  );
}
