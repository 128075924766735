import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { product } from "../../api";
import { formatPrice } from "../../helper/utils";
import {
  IOrderDto,
  IOrderLineItemDto,
  IOrderLocal,
  IProduct,
} from "../../interface/product.interface";
import { DEFAULT_PRODUCT, ORDER_INIT } from "../../mocks/product";
import Slider from "react-slick";
import {
  LOCAL_STORAGE_KEY,
  MESSAGE,
  PHONE_NUMBER,
  PHONE_REGEX,
  ROUTES,
} from "../../constants/general.constant";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const BlockWidget = () => {
  return (
    <>
      <aside id="block_widget-2" className="widget block_widget">
        <div className="row" id="row-1719520698">
          <div id="col-1600447738" className="col small-12 large-12">
            <div className="col-inner">
              <div className="box has-hover   has-hover box-vertical box-text-bottom">
                <div className="box-image" style={{ width: "21%" }}>
                  <div className="">
                    <img
                      width="75"
                      height="75"
                      src="https://shophoahanoi.com/wp-content/uploads/2022/10/fast-delivery.png"
                      className="attachment-original size-original entered lazyloaded"
                      alt="shop hoa tươi hà nội"
                      decoding="async"
                      data-lazy-src="https://shophoahanoi.com/wp-content/uploads/2022/10/fast-delivery.png"
                      data-ll-status="loaded"
                    />
                    <noscript>
                      <img
                        width="75"
                        height="75"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/fast-delivery.png"
                        className="attachment-original size-original"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                      />
                    </noscript>{" "}
                  </div>
                </div>

                <div className="box-text text-center">
                  <div className="box-text-inner">
                    <div id="text-1664844064" className="text">
                      <p>
                        <span style={{ fontSize: "90%" }}>
                          <strong>GIAO NHANH TRONG 60 PHÚT</strong>
                        </span>
                        <br></br>{" "}
                        <span style={{ fontSize: "85%" }}>
                          <em>60 minutes quick delivery</em>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box has-hover   has-hover box-vertical box-text-bottom">
                <div className="box-image" style={{ width: "21%" }}>
                  <div className="">
                    <img
                      width="75"
                      height="75"
                      src="https://shophoahanoi.com/wp-content/uploads/2022/10/medal-1.png"
                      className="attachment-original size-original entered lazyloaded"
                      alt="shop hoa tươi hà nội"
                      decoding="async"
                      data-lazy-src="https://shophoahanoi.com/wp-content/uploads/2022/10/medal-1.png"
                      data-ll-status="loaded"
                    />
                    <noscript>
                      <img
                        width="75"
                        height="75"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/medal-1.png"
                        className="attachment-original size-original"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                      />
                    </noscript>{" "}
                  </div>
                </div>

                <div className="box-text text-center">
                  <div className="box-text-inner">
                    <div id="text-2212355884" className="text">
                      <p>
                        <span style={{ fontSize: "90%" }}>
                          <strong>&nbsp;TẶNG THIỆP CAO CẤP</strong>
                        </span>
                        <br></br>{" "}
                        <span style={{ fontSize: "85%" }}>
                          <em>Free greeting cards</em>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box has-hover   has-hover box-vertical box-text-bottom">
                <div className="box-image" style={{ width: "21%" }}>
                  <div className="">
                    <img
                      width="75"
                      height="75"
                      src="https://shophoahanoi.com/wp-content/uploads/2022/10/recommended-1.png"
                      className="attachment-original size-original entered lazyloaded"
                      alt="shop hoa tươi hà nội"
                      decoding="async"
                      data-lazy-src="https://shophoahanoi.com/wp-content/uploads/2022/10/recommended-1.png"
                      data-ll-status="loaded"
                    />
                    <noscript>
                      <img
                        width="75"
                        height="75"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/recommended-1.png"
                        className="attachment-original size-original"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                      />
                    </noscript>{" "}
                  </div>
                </div>

                <div className="box-text text-center">
                  <div className="box-text-inner">
                    <div id="text-3220443263" className="text">
                      <p>
                        <span style={{ fontSize: "90%" }}>
                          <strong>CAM KẾT HÀI LÒNG 100%</strong>
                        </span>
                        <br></br>{" "}
                        <span style={{ fontSize: "85%" }}>
                          <em>100% guarantee smile</em>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box has-hover   has-hover box-vertical box-text-bottom">
                <div className="box-image" style={{ width: "21%" }}>
                  <div className="">
                    <img
                      width="75"
                      height="75"
                      src="https://shophoahanoi.com/wp-content/uploads/2022/10/free-delivery-1.png"
                      className="attachment-original size-original entered lazyloaded"
                      alt="shop hoa tươi hà nội"
                      decoding="async"
                      data-lazy-src="https://shophoahanoi.com/wp-content/uploads/2022/10/free-delivery-1.png"
                      data-ll-status="loaded"
                    />
                    <noscript>
                      <img
                        width="75"
                        height="75"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/free-delivery-1.png"
                        className="attachment-original size-original"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                      />
                    </noscript>{" "}
                  </div>
                </div>

                <div className="box-text text-center">
                  <div className="box-text-inner">
                    <div id="text-4227182064" className="text">
                      <p>
                        <span style={{ fontSize: "90%" }}>
                          <strong>MIỄN PHÍ GIAO HÀNG 63 TỈNH</strong>
                        </span>
                        <br></br>{" "}
                        <span style={{ fontSize: "85%" }}>
                          <em>Free shipping (nội thành)</em>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

interface IPropsProductDetail {
  getNumberOrder: (o: number) => void;
}

const ProductDetail = (props: IPropsProductDetail) => {
  const { getNumberOrder } = props;
  const { id } = useParams();
  const [data, setData] = useState<IProduct>(DEFAULT_PRODUCT);
  const [favoriteProduct, setFavoriteProduct] = useState<IProduct[]>([]);
  const [relatedProduct, setRelatedProduct] = useState<IProduct[]>([]);
  const [numberProduct, setNumberProduct] = useState(1);
  const [tab, setTab] = useState(1);
  const [show, setShow] = useState(false);
  const [orderData, setOrderData] = useState<IOrderDto>(ORDER_INIT);
  const [showImg, setShowImg] = useState(false);

  useEffect(() => {
    if (!id) return;
    product.getProductById(id).then((res) => {
      setData(res?.data?.data);
    });

    product.getFavoriteProduct().then((res) => {
      setFavoriteProduct(res?.data?.data);
    });

    product.getRelatedProduct(id).then((res) => {
      setRelatedProduct(res?.data?.data);
    });
  }, [id]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleAddToCart = () => {
    const newOrder: IOrderLocal = {
      product: data._id,
      image: data.imageUrl,
      name: data?.name,
      quantity: numberProduct,
      price: data?.price,
      compareAtPrice: data?.compareAtPrice,
    };

    const orderLocal = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY.ORDERS) || "[]"
    );

    const existingOrder = orderLocal.find(
      (order: IOrderLocal) => order.product === newOrder.product
    );
    if (existingOrder) {
      existingOrder.quantity += numberProduct;
    } else {
      orderLocal.push(newOrder);
    }

    getNumberOrder(orderLocal?.length);
    localStorage.setItem(LOCAL_STORAGE_KEY.ORDERS, JSON.stringify(orderLocal));

    window.location.href = ROUTES.PAYMENT;
  };

  const handleOpenContact = () => {
    setShow(true);
    const lineItems: IOrderLineItemDto[] = [
      {
        product: data?._id,
        quantity: 1,
        option: "",
        note: "",
      },
    ];
    setOrderData({
      ...orderData,
      lineItems,
    });
  };

  const handleClose = () => {
    setOrderData(ORDER_INIT);
    setShow(false);
  };

  const handleContactForm = (e: any) => {
    e.preventDefault();
    if (!PHONE_REGEX.test(orderData?.customerPhone)) {
      toast.error(MESSAGE.ERROR_PHONE_NUMBER);
      return;
    }

    product
      .createOrder(orderData)
      .then((res) => {
        toast.success(MESSAGE.SUCCESS_CONTACT);
        setOrderData(ORDER_INIT);
        handleClose();
      })
      .catch((err) => {
        toast.error(MESSAGE.ERROR_CONTACT);
      });
  };

  return (
    <>
      <main id="main" className="">
        <div className="shop-container">
          <div className="container">
            <div className="woocommerce-notices-wrapper"></div>
          </div>
          <div className="product type-product post-2523 status-publish first instock product_cat-hoa-bo-dep product_cat-chua-phan-loai has-post-thumbnail sale featured shipping-taxable purchasable product-type-simple">
            <div className="row content-row row-divided row-large row-reverse m-auto">
              <div
                id="product-sidebar"
                className="col-lg-3 medium-3 hide-for-medium shop-sidebar "
              >
                <BlockWidget />
                <aside
                  id="woocommerce_products-2"
                  className="widget woocommerce widget_products"
                >
                  <span className="widget-title shop-sidebar">
                    Sản phẩm yêu thích
                  </span>
                  <div className="is-divider small"></div>

                  {/* San pham yeu thich */}
                  <ul className="product_list_widget">
                    {favoriteProduct.map((item) => (
                      <li key={item._id}>
                        <a href={`/chi-tiet/${item._id}`}>
                          <img
                            className="attachment-woocommerce_gallery_thumbnail size-woocommerce_gallery_thumbnail lazy-load-active"
                            width="100"
                            height="100"
                            src={item.imageUrl}
                            alt={item.name}
                            decoding="async"
                            loading="lazy"
                            sizes="(max-width: 100px) 100vw, 100px"
                          />{" "}
                          <span className="product-title">{item.name}</span>
                        </a>

                        <div className="price-wrapper">
                          {item?.needContact ? (
                            <span
                              className="price"
                              style={{ color: "red", fontWeight: 700 }}
                            >
                              Giá: Liên Hệ
                            </span>
                          ) : (
                            <span className="price d-flex">
                              {item?.compareAtPrice ? (
                                <del aria-hidden="true">
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">
                                        ₫
                                      </span>
                                      &nbsp;{formatPrice(item?.compareAtPrice)}
                                    </bdi>
                                  </span>
                                </del>
                              ) : (
                                ""
                              )}
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₫
                                  </span>
                                  &nbsp;{formatPrice(item?.price)}
                                </bdi>
                              </span>
                            </span>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </aside>{" "}
              </div>

              <div className="col-lg-9">
                <div className="product-main">
                  <div className="row">
                    <div className="large-7">
                      <div className="h-100 product-images relative mb-half has-hover woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images">
                        <div className="h-100 woocommerce-product-gallery__wrapper product-gallery-slider slider slider-nav-small mb-half slider-lazy-load-active flickity-enabled">
                          <div className="flickity-viewport">
                            <img
                              width="510"
                              height="629"
                              src={data?.imageUrl}
                              className="wp-post-image skip-lazy lazy-load-active"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 510px) 100vw, 510px"
                              onClick={() => setShowImg(true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-info summary entry-summary col-fit product-summary">
                      <nav className="woocommerce-breadcrumb breadcrumbs uppercase">
                        <a href="/">Trang chủ</a>{" "}
                        <span className="divider">/</span>{" "}
                        <a href={`/chi-tiet/${data._id}`}>{data?.name}</a>
                      </nav>
                      <h1 className="product-title product_title entry-title">
                        {data?.name}
                      </h1>

                      <p className="price">
                        {data?.needContact ? (
                          <p
                            className="price"
                            style={{ color: "red", fontWeight: 700 }}
                          >
                            Giá: Liên Hệ
                          </p>
                        ) : (
                          <>
                            {data?.compareAtPrice ? (
                              <del aria-hidden="true">
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      ₫
                                    </span>
                                    &nbsp;{formatPrice(data?.compareAtPrice)}
                                  </bdi>
                                </span>
                              </del>
                            ) : (
                              ""
                            )}
                            <ins>
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₫
                                  </span>
                                  &nbsp;{formatPrice(data.price)}
                                </bdi>
                              </span>
                            </ins>
                          </>
                        )}
                      </p>
                      <div className=" special-promotion">
                        <div className="col-inner" style={{ padding: 15 }}>
                          <div
                            className="is-border"
                            style={{
                              borderColor: "rgb(255, 161, 39)",
                              borderRadius: 5,
                              borderWidth: 3,
                            }}
                          ></div>

                          <div className="title_promobox">
                            <span style={{ fontSize: "110%" }}>
                              <strong>ƯU ĐÃI ĐẶC BIỆT</strong>
                            </span>
                          </div>
                          <p>
                            <span style={{ color: "#288ad6" }}>
                              <strong>
                                <span style={{ color: "#ed1c24" }}>1.</span>{" "}
                              </strong>
                              Tặng Banner Hoặc Thiệp (Trị Giá 20.000đ - 50.000đ)
                              Miễn Phí
                            </span>
                            <br></br>
                            <span style={{ color: "#288ad6" }}>
                              <strong>
                                <span style={{ color: "#ed1c24" }}>2.</span>{" "}
                              </strong>
                              Giao Miễn Phí trong bán kính 10km
                            </span>
                            <br></br>
                            <span style={{ color: "#288ad6" }}>
                              <strong>
                                <span style={{ color: "#ed1c24" }}>3.</span>{" "}
                              </strong>
                              Giao Gấp Trong Vòng 60 phút
                            </span>
                            <br></br>
                            <span style={{ color: "#288ad6" }}>
                              <span style={{ color: "#ed1c24" }}>
                                <strong>4.</strong>{" "}
                              </span>
                              Cam Kết 100% Hoàn Lại Tiền Nếu Bạn Không Hài Lòng
                            </span>
                            <br></br>
                            <span style={{ color: "#288ad6" }}>
                              <span style={{ color: "#ed1c24" }}>
                                <strong>5.</strong>{" "}
                              </span>
                              Gửi Hình Hoa Trước Và Sau Khi Giao
                            </span>
                            <br></br>
                            <span style={{ color: "#288ad6" }}>
                              <span style={{ color: "#ed1c24" }}>
                                <strong>6.</strong>{" "}
                              </span>
                              Cam Kết Hoa Tươi Trên 3 Ngày
                            </span>
                            <br></br>
                            <span style={{ color: "#288ad6" }}>
                              <span style={{ color: "#ed1c24" }}>
                                <strong>7.</strong>{" "}
                              </span>
                              Có Hóa Đơn VAT 10%
                            </span>
                          </p>
                        </div>
                      </div>

                      <div
                        id="gap-676086276"
                        className="gap-element clearfix"
                        style={{
                          display: "block",
                          height: "auto",
                          paddingTop: "1rem",
                        }}
                      ></div>

                      <div>
                        <div className="d-flex justify-content-between">
                          <button
                            type="submit"
                            name="add-to-cart"
                            className="single_add_to_cart_button button alt"
                            onClick={handleAddToCart}
                          >
                            Đặt hàng
                          </button>
                          <button
                            type="button"
                            className="single_add_to_cart_button button alt"
                            onClick={handleOpenContact}
                          >
                            Liên hệ nhanh
                          </button>
                        </div>
                        <a
                          type="button"
                          className=" btn btn-danger w-100"
                          href={`tel:${PHONE_NUMBER}`}
                        >
                          Gọi ngay: {PHONE_NUMBER}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-footer">
                  <div className="woocommerce-tabs wc-tabs-wrapper container tabbed-content">
                    <ul
                      className="tabs wc-tabs product-tabs small-nav-collapse nav nav-uppercase nav-line nav-left"
                      role="tablist"
                    >
                      <li
                        className={`description_tab ${tab === 1 && "active"}`}
                        id="tab-title-description"
                        role="tab"
                        aria-controls="tab-description"
                        onClick={() => setTab(1)}
                      >
                        <a href="#tab-description">Mô tả</a>
                      </li>
                      <li
                        className={`reviews_tab ${tab === 2 && "active"}`}
                        id="tab-title-reviews"
                        role="tab"
                        aria-controls="tab-reviews"
                        onClick={() => setTab(2)}
                      >
                        <a href="#tab-reviews">Đánh giá</a>
                      </li>
                    </ul>
                    <div className="tab-panels">
                      {/* Mô tả */}
                      <div
                        className={`woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content ${
                          tab === 1 && "active"
                        }`}
                        id="tab-description"
                        role="tabpanel"
                        aria-labelledby="tab-title-description"
                      >
                        <div style={{ whiteSpace: "pre-line" }}>
                          {data?.description}
                        </div>

                        <p>&nbsp;</p>
                      </div>
                      {/* Đánh giá */}
                      <div
                        className={`woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content ${
                          tab === 2 && "active"
                        }`}
                        id="tab-reviews"
                        role="tabpanel"
                        aria-labelledby="tab-title-reviews"
                      >
                        <div id="reviews" className="woocommerce-Reviews row">
                          <div id="comments" className="large-12">
                            <h3 className="woocommerce-Reviews-title normal">
                              Đánh giá{" "}
                            </h3>

                            <p className="woocommerce-noreviews">
                              Chưa có đánh giá nào.
                            </p>
                          </div>

                          <div id="review_form_wrapper" className="large-12">
                            <div id="review_form" className="col-inner">
                              <div className="review-form-inner has-border">
                                <div id="respond" className="comment-respond">
                                  <h3
                                    id="reply-title"
                                    className="comment-reply-title"
                                  >
                                    Hãy là người đầu tiên nhận xét "{data.name}"
                                    <small>
                                      <a
                                        rel="nofollow"
                                        id="cancel-comment-reply-link"
                                        href="/mua/tinh-anh/#respond"
                                        style={{ display: "none" }}
                                      >
                                        Hủy
                                      </a>
                                    </small>
                                  </h3>
                                  <form
                                    id="commentform"
                                    className="comment-form"
                                  >
                                    <div className="comment-form-rating">
                                      <label htmlFor="rating">
                                        Đánh giá của bạn&nbsp;
                                        <span className="required">*</span>
                                      </label>
                                      <p className="stars">
                                        {" "}
                                        <span className="ps-0">
                                          <i className="bi bi-star-fill"></i>
                                        </span>
                                        <span>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                        </span>
                                        <span>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                        </span>
                                        <span>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                        </span>
                                        <span>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                          <i className="bi bi-star-fill"></i>
                                        </span>
                                      </p>
                                      <select
                                        name="rating"
                                        id="rating"
                                        required
                                        style={{ display: "none" }}
                                      >
                                        <option value="">Xếp hạng…</option>
                                        <option value="5">Rất tốt</option>
                                        <option value="4">Tốt</option>
                                        <option value="3">Trung bình</option>
                                        <option value="2">Không tệ</option>
                                        <option value="1">Rất tệ</option>
                                      </select>
                                    </div>
                                    <p className="comment-form-comment">
                                      <label htmlFor="comment">
                                        Nhận xét của bạn&nbsp;
                                        <span className="required">*</span>
                                      </label>
                                      <textarea
                                        id="comment"
                                        name="comment"
                                        cols={45}
                                        rows={8}
                                        required
                                      ></textarea>
                                    </p>
                                    <p className="comment-form-author">
                                      <label htmlFor="author">
                                        Tên&nbsp;
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        id="author"
                                        name="author"
                                        type="text"
                                        // value=""
                                        size={30}
                                        required
                                      />
                                    </p>
                                    <p className="comment-form-email">
                                      <label htmlFor="email">
                                        Email&nbsp;
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        // value=""
                                        size={30}
                                        required
                                      />
                                    </p>
                                    <p className="comment-form-cookies-consent">
                                      <input
                                        id="wp-comment-cookies-consent"
                                        name="wp-comment-cookies-consent"
                                        type="checkbox"
                                        value="yes"
                                      />{" "}
                                      <label htmlFor="wp-comment-cookies-consent">
                                        Lưu tên của tôi, email, và trang web
                                        trong trình duyệt này cho lần bình luận
                                        kế tiếp của tôi.
                                      </label>
                                    </p>
                                    <p className="form-submit">
                                      <input
                                        name="submit"
                                        type="submit"
                                        id="submit"
                                        className="submit"
                                        value="Gửi đi"
                                      />{" "}
                                      <input
                                        type="hidden"
                                        name="comment_post_ID"
                                        value="2523"
                                        id="comment_post_ID"
                                      />
                                      <input
                                        type="hidden"
                                        name="comment_parent"
                                        id="comment_parent"
                                        value="0"
                                      />
                                    </p>
                                  </form>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="related related-products-wrapper product-section">
                    <h3 className="product-section-title container-width product-section-title-related pt-half pb-half uppercase">
                      Sản phẩm tương tự{" "}
                    </h3>
                    <Slider {...settings}>
                      {relatedProduct.map((item) => (
                        <div
                          key={item._id}
                          className="product-small col has-hover product type-product post-2335 status-publish instock product_cat-chua-phan-loai has-post-thumbnail shipping-taxable purchasable product-type-simple"
                        >
                          <div className="col-inner">
                            <div className="badge-container absolute left top z-1"></div>
                            <div className="product-small box ">
                              <div className="box-image">
                                <div className="image-zoom">
                                  <a href={`/chi-tiet/${item._id}`}>
                                    <img
                                      width="247"
                                      height="296"
                                      src={item.imageUrl}
                                      className="lazy-load attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                      alt=""
                                      decoding="async"
                                    />
                                  </a>
                                </div>
                              </div>

                              <div className="box-text box-text-products text-center grid-style-2">
                                <div className="title-wrapper">
                                  <p className="name product-title woocommerce-loop-product__title">
                                    <a
                                      href={`/chi-tiet/${item._id}`}
                                      className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                                    >
                                      {item.name}
                                    </a>
                                  </p>
                                </div>
                                <div className="price-wrapper">
                                  {item?.needContact ? (
                                    <span
                                      className="price"
                                      style={{ color: "red", fontWeight: 700 }}
                                    >
                                      Giá: Liên Hệ
                                    </span>
                                  ) : (
                                    <span className="price d-flex justify-content-center">
                                      {item?.compareAtPrice ? (
                                        <del aria-hidden="true">
                                          <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                              <span className="woocommerce-Price-currencySymbol">
                                                ₫
                                              </span>
                                              &nbsp;
                                              {formatPrice(
                                                item?.compareAtPrice
                                              )}
                                            </bdi>
                                          </span>
                                        </del>
                                      ) : (
                                        ""
                                      )}
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            ₫
                                          </span>
                                          &nbsp;{formatPrice(item?.price)}
                                        </bdi>
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal show={show} onHide={handleClose} className="pop-up-product-detail">
        <Modal.Header>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            className="close cart-modal-close"
            onClick={handleClose}
          >
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMTUuMzg0NkwxNS4zODQ2IDBMMTYgMC42MTUzODRMMC42MTUzODQgMTZMMCAxNS4zODQ2WiIgZmlsbD0iYmxhY2siLz4KPHBhdGggZD0iTTYuNjM5NzVlLTA2IDAuNjE1Mzk1TDE1LjM4NDYgMTZMMTYgMTUuMzg0NkwwLjYxNTM5MSAxLjAzNzQ2ZS0wNUw2LjYzOTc1ZS0wNiAwLjYxNTM5NVoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo="
              alt=""
              className="cart-modal-close-icon"
            />
          </button>
          <h5
            id="cardModalLabel"
            className="modal-title cart-modal-title text-center fw-bold"
          >
            ĐẶT HOA NHANH
          </h5>
        </Modal.Header>
        <form
          name="contact"
          action=""
          className="cart"
          onSubmit={handleContactForm}
        >
          <Modal.Body>
            <p>
              Quý khách vui lòng điền thông tin bên dưới và bấm nút{" "}
              <strong>xác nhận</strong>, nhân viên chúng tôi sẽ gọi quý khách
              trong ít phút để hỗ trợ đặt hàng
            </p>
            <section className="card-product-note mb-3">
              <div className="card-product-note-item">
                <label htmlFor="customerName">Họ tên</label>
                <input
                  type="text"
                  id="customerName"
                  placeholder="Nhập tên"
                  className="card-product-text"
                  required
                  value={orderData?.customerName}
                  onChange={(e) =>
                    setOrderData({
                      ...orderData,
                      customerName: e.target.value,
                    })
                  }
                />
              </div>
            </section>
            <section className="card-product-note">
              <div className="card-product-note-item">
                <label htmlFor="customerPhone">Số điện thoại</label>
                <input
                  type="text"
                  id="customerPhone"
                  required
                  placeholder="Nhập số điện thoại"
                  className="card-product-text"
                  value={orderData?.customerPhone}
                  onChange={(e) =>
                    setOrderData({
                      ...orderData,
                      customerPhone: e.target.value,
                    })
                  }
                />
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="single_add_to_cart_button button alt"
            >
              Xác nhận
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showImg}
        onHide={() => setShowImg(false)}
        closeButton
        className="modal-img"
      >
        <img src={data?.imageUrl} alt="" decoding="async" />
      </Modal>

      <ToastContainer autoClose={500} />
    </>
  );
};

export default React.memo(ProductDetail);
