import { ICategory, IOrderDto } from "../interface/product.interface";

export const CATEGORY_INIT: ICategory = {
  _id: "",
  name: "",
  description: "",
  imageUrl: "",
  code: "",
  products: [],
};

export const DEFAULT_PRODUCT = {
  _id: "",
  name: "",
  description: "",
  imageUrl: "",
  code: "",
  price: 0,
  compareAtPrice: 0,
  tag: "",
  needContact: false,
};

export const ORDER_INIT: IOrderDto = {
  customerName: "",
  customerCompany: "",
  customerAddress: "",
  customerEmail: "",
  customerCity: "",
  zip: "",
  customerPhone: "",
  orderNote: "",
  paymentMethod: "Tien mat",
  discount: 0,
  lineItems: [],
};
