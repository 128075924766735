/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { product } from "../../api";
import "../../assets/style/Home.css";
import { WEB_NAME } from "../../constants/general.constant";
import { formatPrice } from "../../helper/utils";
import { ICategory, IProduct } from "../../interface/product.interface";
import { CATEGORY_INIT } from "../../mocks/product";
import Slider from "react-slick";

interface IPropsContent {}

const FEATURE_TAG = [
  {
    img: "https://shophoahanoi.com/wp-content/uploads/2022/10/giao-hoa-hoa-toc.png",
    title: "GIAO HOA HỎA TỐC",
    content: "Giao hoa trong 60 phút đến 90 phút",
  },
  {
    img: "https://shophoahanoi.com/wp-content/uploads/2022/10/icon-free-png.png",
    title: "MIỄN PHÍ THIỆP, BĂNG RÔN",
    content: " Tặng thiệp, băng rôn (banner) kèm theo để ghi nội dung trên hoa",
  },
  {
    img: "https://shophoahanoi.com/wp-content/uploads/2022/10/icon-ship-png.png",
    title: "GIAO HOA MIỄN PHÍ",
    content:
      " Dành cho hóa đơn trên 500k với hoa giỏ, hoa bó và 900k với hoa kệ cao",
  },
  {
    img: "https://shophoahanoi.com/wp-content/uploads/2022/10/icon-image-png.png",
    title: "CHỤP HÌNH TRƯỚC",
    content: "Chụp hình xem trước, thông báo giao hoa xong khi hoàn thành.",
  },
];

const FeatureTag = () => {
  return (
    <section className="section tieu-chi-section pb-0">
      <div className="section-content relative">
        <div className="row row-small" id="row-701223830">
          {FEATURE_TAG.map((item, idx) => (
            <div key={idx} className=" medium-3 small-12 large-3 pb-3">
              <div
                className="col-inner dark"
                style={{
                  backgroundColor: "rgb(120, 176, 4)",
                  borderRadius: 14,
                }}
              >
                <div className="icon-box featured-box tag-box icon-box-left text-left">
                  <div
                    className="icon-box-img has-icon-bg"
                    style={{ width: 50 }}
                  >
                    <div className="icon">
                      <div
                        className="icon-inner"
                        style={{ borderWidth: 1, color: "rgb(255, 152, 0)" }}
                      >
                        <img
                          src={item.img}
                          className="attachment-medium size-medium lazy-load-active"
                          alt="ảnh hoa"
                        />{" "}
                      </div>
                    </div>
                  </div>
                  <div className="icon-box-text last-reset">
                    <div className="text">
                      <span>{item.title}</span>
                      <p>
                        <em>{item.content}</em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

interface IPropCategoryList {
  categories: ICategory[];
  handleGetCategoryById: (id: string) => void;
}

const CategoryList = (props: IPropCategoryList) => {
  const { categories, handleGetCategoryById } = props;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <section className="section tieu-chi-section danh-muc-san-pham">
      <div className="bg section-bg fill bg-fill bg-loaded"></div>

      <div className="section-content relative">
        <div className="row row-small">
          <div>
            <div className="col-inner">
              <h2 className="text-center mb-4">
                <strong>DANH MỤC SẢN PHẨM</strong>
              </h2>
            </div>
          </div>
        </div>
        <div className="row row-small">
          <Slider {...settings}>
            {categories?.map((item) => (
              <div
                key={item?._id}
                id={item?._id}
                onClick={() => {
                  handleGetCategoryById(item?._id);
                  window.location.href = "#category-section";
                }}
              >
                <div className="col-inner">
                  <div className="box has-hover   has-hover box-default box-text-bottom">
                    <div className="box-image">
                      <div className="image-zoom">
                        <img
                          width="175"
                          height="175"
                          src={item?.imageUrl}
                          data-src={item?.imageUrl}
                          className="attachment-original size-original lazy-load-active"
                          alt={item?.name}
                          decoding="async"
                          loading="lazy"
                          sizes="(max-width: 175px) 100vw, 175px"
                        />
                      </div>
                    </div>

                    <div className="box-text text-center">
                      <div className="box-text-inner">
                        <p className="text-uppercase">
                          <strong>{item?.name}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

interface IPropCategoryTop {
  activeCategory: ICategory;
  productList: IProduct[];
}
const CategoryTop = (props: IPropCategoryTop) => {
  const { activeCategory, productList } = props;

  return (
    <section className="section tieu-chi-section" id="category-section">
      <div className="bg section-bg fill bg-fill bg-loaded"></div>
      <div className="section-content relative">
        <div className="row row-small" id="row-231772975">
          <div id="col-367485692" className="col small-12 large-12">
            <div className="col-inner text-center">
              <div className="container section-title-container">
                <h2 className="section-title section-title-center text-uppercase">
                  <b></b>
                  <span className="section-title-main mb-3">
                    {activeCategory?.name
                      ? `TOP MẪU ${activeCategory?.name} MỚI NHẤT`
                      : "TOP MẪU HOA THEO GIÁ"}
                  </span>
                  <b></b>
                </h2>
              </div>

              <div className="row large-columns-5 medium-columns-3 small-columns-2 row-small">
                {productList?.map((item) => (
                  <div className="col" key={item?._id}>
                    <div className="col-inner">
                      <div className="badge-container absolute left top z-1"></div>
                      <div className="product-small box has-hover box-normal box-text-bottom">
                        <div className="box-image">
                          <div
                            className="image-zoom image-cover"
                            style={{ paddingTop: "120%" }}
                          >
                            <a href={`chi-tiet/${item._id}`}>
                              <img
                                width="527"
                                height="650"
                                src={item?.imageUrl}
                                className="attachment-original size-original lazy-load-active"
                                alt={item?.name}
                                decoding="async"
                                loading="lazy"
                                sizes="(max-width: 527px) 100vw, 527px"
                              />
                            </a>
                          </div>
                        </div>

                        <div
                          className="box-text text-center"
                          style={{ backgroundColor: "rgb(255, 255, 255)" }}
                        >
                          <div className="title-wrapper">
                            <p className="name product-title woocommerce-loop-product__title">
                              <a
                                href={`chi-tiet/${item._id}`}
                                className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                              >
                                {item?.name}
                              </a>
                            </p>
                          </div>
                          <div className="price-wrapper">
                            {item?.needContact ? (
                              <span
                                className="price"
                                style={{ color: "red", fontWeight: 700 }}
                              >
                                Giá: Liên Hệ
                              </span>
                            ) : (
                              <span className="price d-flex justify-content-center">
                                {item?.compareAtPrice ? (
                                  <del aria-hidden="true">
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          ₫
                                        </span>
                                        &nbsp;
                                        {formatPrice(item?.compareAtPrice)}
                                      </bdi>
                                    </span>
                                  </del>
                                ) : (
                                  ""
                                )}
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      ₫
                                    </span>
                                    &nbsp;{formatPrice(item?.price)}
                                  </bdi>
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CommitmentQuality = () => {
  return (
    <section className="section tieu-chi-section" id="section_283502653">
      <div className="bg section-bg fill bg-fill bg-loaded"></div>

      <div className="section-content relative">
        <div className="row row-small" id="row-1068109686">
          <div id="col-1774888287" className="col small-12 large-12">
            <div className="col-inner">
              <h3 style={{ textAlign: "center" }}>
                <span style={{ fontSize: "160%" }}>
                  <strong>TẠI SAO BẠN NÊN DÙNG DỊCH VỤ CỦA CHÚNG TÔI?</strong>
                </span>
              </h3>
              <p>
                <span style={{ color: "var(--main-color)" }}>
                  <strong>{WEB_NAME}</strong>
                </span>{" "}
                Shop Hoa Đám Tang - Hoa Chia Buồn - Hoa Viếng với sứ mệnh được
                ủy thác, chúng tôi luôn hướng đến dịch vụ làm Hoa Đám Tang đẹp,
                giá rẻ nhất, thành kính nhất thay người gửi trao đến người nhận.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-small" id="row-721154036">
          <div className=" medium-4 small-6 large-4">
            <div className="col-inner text-center box-shadow-1 box-shadow-1-hover">
              <div className="icon-box featured-box icon-box-top text-left">
                <div className="icon-box-img has-icon-bg" style={{ width: 60 }}>
                  <div className="icon w-100">
                    <div
                      className="icon-inner"
                      style={{ borderWidth: 1, color: "rgb(255, 152, 0)" }}
                    >
                      <img
                        width="64"
                        height="64"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-fresh-warranty.webp"
                        data-src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-fresh-warranty.webp"
                        className="attachment-medium size-medium lazy-load-active"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                        loading="lazy"
                        title="Hoa Hà Nội"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="icon-box-text last-reset">
                  <div id="text-1826380574" className="text">
                    <p>
                      <strong>MẪU HOA MỚI ĐẸP VÀ CHẤT LƯỢNG</strong>
                    </p>
                    <p>
                      Chúng tôi sử dụng 100% hoa tươi tự nhiên để tạo ra những
                      mẫu hoa đẹp nhất.
                      <strong>
                        <span style={{ color: "var(--main-color)" }}>
                          {" "}
                          {WEB_NAME}
                        </span>
                      </strong>{" "}
                      nhận bó hoa, cắm hoa theo yêu cầu của khách hàng.<br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" medium-4 small-6 large-4">
            <div className="col-inner text-center box-shadow-1 box-shadow-1-hover">
              <div className="icon-box featured-box icon-box-top text-left">
                <div className="icon-box-img has-icon-bg" style={{ width: 60 }}>
                  <div className="icon w-100">
                    <div
                      className="icon-inner"
                      style={{ borderWidth: 1, color: "rgb(255, 152, 0)" }}
                    >
                      <img
                        width="64"
                        height="64"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-guarantee-smile.webp"
                        data-src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-guarantee-smile.webp"
                        className="attachment-medium size-medium lazy-load-active"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                        loading="lazy"
                        title="Hoa Hà Nội"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="icon-box-text last-reset">
                  <div id="text-1358608799" className="text">
                    <p>
                      <strong>CAM KẾT BÁN ĐÚNG SẢN PHẨM NHƯ HÌNH</strong>
                    </p>
                    <p>
                      Những sản phẩm trên website, facebook của{" "}
                      <span style={{ color: "var(--main-color)" }}>
                        <strong>{WEB_NAME}</strong>{" "}
                      </span>
                      đều là sản phẩm do shop tự thực hiện. Nếu nhận hoa không
                      đúng như hình đặt, bạn&nbsp;<strong>KHÔNG</strong>
                      &nbsp;cần thanh toán.<br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" medium-4 small-6 large-4">
            <div className="col-inner text-center box-shadow-1 box-shadow-1-hover">
              <div className="icon-box featured-box icon-box-top text-left">
                <div className="icon-box-img has-icon-bg" style={{ width: 60 }}>
                  <div className="icon w-100">
                    <div
                      className="icon-inner"
                      style={{ borderWidth: 1, color: "rgb(255, 152, 0)" }}
                    >
                      <img
                        width="64"
                        height="64"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-support-247.webp"
                        data-src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-support-247.webp"
                        className="attachment-medium size-medium lazy-load-active"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                        loading="lazy"
                        title="Hoa Hà Nội"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="icon-box-text last-reset">
                  <div id="text-1235174322" className="text">
                    <p>
                      <strong>ĐỘI NGŨ CHUYÊN NGHIỆP</strong>
                    </p>
                    <p>
                      <strong>
                        <span style={{ color: "var(--main-color)" }}>
                          {WEB_NAME}
                        </span>
                      </strong>{" "}
                      rất hạnh phúc khi có đội ngũ thợ cắm hoa tay nghề cao,
                      nhân viên tư vấn nhiệt tình, sáng tạo và có những tác phẩm
                      nghệ thuật cắm hoa đặc sắc, ấn tượng và độc đáo.<br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" medium-4 small-6 large-4">
            <div className="col-inner text-center box-shadow-1 box-shadow-1-hover">
              <div className="icon-box featured-box icon-box-top text-left">
                <div className="icon-box-img has-icon-bg" style={{ width: 60 }}>
                  <div className="icon w-100">
                    <div
                      className="icon-inner"
                      style={{ borderWidth: 1, color: "rgb(255, 152, 0)" }}
                    >
                      <img
                        width="64"
                        height="64"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-postcard.webp"
                        data-src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-postcard.webp"
                        className="attachment-medium size-medium lazy-load-active"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                        loading="lazy"
                        title="Hoa Hà Nội"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="icon-box-text last-reset">
                  <div id="text-37073613" className="text">
                    <p>
                      <strong>THANH TOÁN LINH HOẠT</strong>
                    </p>
                    <p>
                      Quý khách có thể thanh toán trực tiếp, chuyển khoản qua
                      các ngân hàng BIDV, Vietcombank, Techcombank, Agribank… và
                      giao hàng thu tiền.<br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" medium-4 small-6 large-4">
            <div className="col-inner text-center box-shadow-1 box-shadow-1-hover">
              <div className="icon-box featured-box icon-box-top text-left">
                <div className="icon-box-img has-icon-bg" style={{ width: 60 }}>
                  <div className="icon w-100">
                    <div
                      className="icon-inner"
                      style={{ borderWidth: 1, color: "rgb(255, 152, 0)" }}
                    >
                      <img
                        width="64"
                        height="64"
                        src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-discount.webp"
                        data-src="https://shophoahanoi.com/wp-content/uploads/2022/10/icon-discount.webp"
                        className="attachment-medium size-medium lazy-load-active"
                        alt="shop hoa tươi hà nội"
                        decoding="async"
                        loading="lazy"
                        title="Hoa Hà Nội"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="icon-box-text last-reset">
                  <div id="text-529566371" className="text">
                    <p>
                      <strong>SHIP COD TOÀN QUỐC</strong>
                    </p>
                    <p>
                      Dịch vụ gửi điện hoa trên toàn quốc của{" "}
                      <span style={{ color: "var(--main-color)" }}>
                        <strong>{WEB_NAME}</strong>{" "}
                      </span>
                      cực kì an toàn và thuận tiện, bạn chỉ cần trả tiền khi đã
                      nhận được hàng.<br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" medium-4 small-6 large-4">
            <div className="col-inner text-center box-shadow-1 box-shadow-1-hover">
              <div className="icon-box featured-box icon-box-top text-left">
                <div className="icon-box-img has-icon-bg" style={{ width: 60 }}>
                  <div className="icon w-100">
                    <div
                      className="icon-inner"
                      style={{ borderWidth: 1, color: "rgb(255, 152, 0)" }}
                    >
                      <i
                        style={{ fontSize: 34, marginTop: 2, color: "pink" }}
                        className="bi bi-cash-coin "
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="icon-box-text last-reset">
                  <div id="text-1080364402" className="text">
                    <p>
                      <strong>HẬU MÃI CHO KHÁCH HÀNG</strong>
                    </p>
                    <p>
                      Có chiết khấu % cao cho khách hàng tái đặt hoa tại shop{" "}
                      <span style={{ color: "var(--main-color)" }}>
                        <strong>{WEB_NAME}</strong>
                      </span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PRICE_FILTER = [
  {
    from: 400000,
    to: 800000,
  },
  {
    from: 800000,
    to: 1200000,
  },
  {
    from: 1200000,
    to: 1600000,
  },
  {
    from: 1600000,
    to: 2000000,
  },
  {
    from: 2000000,
    to: 2400000,
  },
  {
    from: 2400000,
    to: 2800000,
  },
  {
    from: 2800000,
    to: 3200000,
  },
];

export default function Content(props: IPropsContent) {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [productList, setProductList] = useState<IProduct[]>([]);
  const [activeCategory, setActiveCategory] =
    useState<ICategory>(CATEGORY_INIT);

  const [valueFilter, setValueFilter] = useState("");
  useEffect(() => {
    product.getListCategory().then((res) => {
      setCategories(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (categories && categories[0]?._id) {
      setActiveCategory(categories[0]);
      product.getListCategoryById(categories[0]?._id).then((res) => {
        setProductList(res?.data?.data?.products);
      });
    }
  }, [categories]);

  const handleGetCategoryById = (id: string) => {
    if (!id) return;
    const category = categories?.find((i) => i._id === id) || CATEGORY_INIT;
    setActiveCategory(category);
    product.getListCategoryById(id).then((res) => {
      setProductList(res?.data?.data?.products);
    });
  };

  const handleFilterByPrice = (
    value: string,
    priceFrom: number,
    priceTo: number
  ) => {
    setValueFilter(value);
    product.getAllProduct(priceFrom, priceTo).then((res) => {
      const result = res?.data?.data.sort(
        (a: IProduct, b: IProduct) => Number(a.price) - Number(b.price)
      );
      setActiveCategory(CATEGORY_INIT);
      setProductList(result);
    });
  };

  return (
    <>
      <main>
        {/* Tag giới thiệu */}
        <FeatureTag />

        {/* Danh mục sản phẩm */}
        <CategoryList
          categories={categories}
          handleGetCategoryById={handleGetCategoryById}
        />

        <section className="section tieu-chi-section">
          <div className="section-content relative">
            <div className="row row-small">
              <h4 className="fw-bold">Lọc theo giá</h4>
              {/* <div className="d-flex justify-content-between"> */}
              {PRICE_FILTER.map((item, idx) => (
                <div key={idx} className="large-3 small-4">
                  <div
                    className="form-check"
                    onClick={() => (window.location.href = "#category-section")}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`${item.from}-${item.to}`}
                      value={`${item.from}-${item.to}`}
                      onChange={(e) =>
                        handleFilterByPrice(e.target.id, item.from, item.to)
                      }
                      checked={`${item.from}-${item.to}` === valueFilter}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`${item.from}-${item.to}`}
                    >
                      {formatPrice(item.from)}đ - {formatPrice(item.to)}đ
                    </label>
                  </div>
                </div>
              ))}
              <div className="col-lg-3 small-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="1"
                    onChange={(e) =>
                      handleFilterByPrice(e.target.id, 3200000, 0)
                    }
                    checked={`1` === valueFilter}
                  />
                  <label className="form-check-label" htmlFor="1">
                    Trên 3.200.000đ
                  </label>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </section>

        {/* Top sản phẩm */}
        <CategoryTop
          activeCategory={activeCategory}
          productList={productList}
        />

        {/* Cam kết chất lượng */}
        <CommitmentQuality />
      </main>
    </>
  );
}
