import { AxiosRequestConfig } from "axios";

const axios: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_BASE_API}/api`,
  responseType: "json",
  timeout: 30000,
  headers: {
    "ngrok-skip-browser-warning": true,
  },
};

const AppConfig = {
  axios,
};

export default AppConfig;
