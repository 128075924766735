import { BrowserRouter, Route, Routes } from "react-router-dom";
import CategoryProduct from "../page/CategoryPage/CategoryPage";
import OrderPage from "../page/CheckoutPage/OrderPage";
import ProductDetail from "../page/DetailPage/ProductDetail";
import Home from "../page/HomePage/Home";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { LOCAL_STORAGE_KEY } from "../constants/general.constant";
import { BlogPage } from "../page/BlogPage/BlogPage";

const RouterDom = () => {
  const [numberOrds, setNumberOrds] = useState(0);

  useEffect(() => {
    setNumberOrds(
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.ORDERS) || "[]").length
    );
  }, []);

  const getNumberOrder = (numberOrds: number) => {
    setNumberOrds(numberOrds);
  };
  return (
    <>
      <Header numberOrds={numberOrds} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/payment"
            element={<OrderPage getNumberOrder={getNumberOrder} />}
          />
          <Route path="/danh-muc-san-pham/:id" element={<CategoryProduct />} />
          <Route
            path="/chi-tiet/:id"
            element={<ProductDetail getNumberOrder={getNumberOrder} />}
          />
          <Route path="/:slug" element={<BlogPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
};

export default RouterDom;
