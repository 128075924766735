export const LOCAL_STORAGE_KEY = {
  ORDERS: "orders",
};

export const WEB_NAME = 'Shop Hoa Tang - Hoa Chia Buồn'
export const PHONE_NUMBER = "0985238545";
export const ORDER_STATUS = {
  PENDING: "Pending",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};

export const PAYMENT_METHOD = {
  CASH: "Tien mat",
  TRANSFER: "Chuyen khoan",
};

export const MESSAGE = {
  SUCCESS_ORDER: "Đặt hàng thành công",
  SUCCESS_CONTACT: "Vui lòng đợi liên hệ trong ít phút",
  SUCCESS_ADD_TO_CART: "Thêm vào giỏ hàng thành công",

  ERROR_ORDER: "Đặt hàng không thành công",
  ERROR_CONTACT: "Gửi liên hệ không thành công",
  ERROR_PHONE_NUMBER: "Số điện thoại không hợp lệ",
};

export const PHONE_REGEX = /^\+?\d{1,3}\d{9,15}$/;

export const ROUTES = {
  PAYMENT: "/payment",
}