/* eslint-disable jsx-a11y/anchor-is-valid */
import { product } from "../api";
import "../assets/style/Home.css";
import { useEffect, useState } from "react";
import { ICategory } from "../interface/product.interface";
import { PHONE_NUMBER, ROUTES, WEB_NAME } from "../constants/general.constant";

interface IPropsHeader {
  numberOrds: number;
}

export default function Header({ numberOrds }: IPropsHeader) {
  const [opensideBar, setOpenSideBar] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    product.getListCategory().then((res) => {
      setCategories(res?.data?.data);
    });
  }, []);

  const renderSideBar = () => {
    return (
      <>
        <div className="mfp-bg off-canvas off-canvas-left main-menu-overlay mfp-ready"></div>
        <div
          className="mfp-wrap mfp-auto-cursor off-canvas off-canvas-left mfp-ready"
          tabIndex={-1}
          style={{ overflow: "hidden auto" }}
        >
          <div className="mfp-container mfp-s-ready mfp-inline-holder">
            <div className="mfp-content">
              <div id="main-menu" className="mobile-sidebar no-scrollbar">
                <div className="sidebar-menu no-scrollbar ">
                  <ul
                    className="nav nav-sidebar nav-vertical nav-uppercase"
                    data-tab="1"
                  >
                    <li className="header-search-form search-form html relative has-icon">
                      <div className="header-search-form-wrapper">
                        <div className="searchform-wrapper ux-search-box relative is-normal">
                          <form
                            role="search"
                            method="get"
                            className="searchform"
                          >
                            <div className="flex-row relative">
                              <div className="flex-col flex-grow">
                                <label
                                  className="screen-reader-text"
                                  htmlFor="woocommerce-product-search-field-1"
                                >
                                  Tìm kiếm:
                                </label>
                                <input
                                  type="search"
                                  id="woocommerce-product-search-field-1"
                                  className="search-field mb-0"
                                  placeholder="Tìm kiếm sản phẩm..."
                                  name="s"
                                  autoComplete="off"
                                />
                                <input
                                  type="hidden"
                                  name="post_type"
                                  value="product"
                                />
                              </div>
                              <div className="flex-col">
                                <button
                                  type="submit"
                                  value="Tìm kiếm"
                                  className="ux-search-submit submit-button secondary button icon mb-0"
                                  aria-label="Submit"
                                >
                                  <i className="bi bi-search text-white"></i>{" "}
                                </button>
                              </div>
                            </div>
                            <div className="live-search-results text-left z-top">
                              <div
                                className="autocomplete-suggestions"
                                style={{
                                  position: "absolute",
                                  display: "none",
                                  maxHeight: 300,
                                  zIndex: 9999,
                                }}
                              ></div>
                            </div>
                          </form>
                        </div>{" "}
                      </div>
                    </li>
                    {categories.map((item) => (
                      <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-3320">
                        <a href={`/danh-muc-san-pham/${item._id}`}>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mfp-preloader">Loading...</div>
          </div>
          <button
            title="Close (Esc)"
            type="button"
            className="mfp-close"
            onClick={() => setOpenSideBar(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </>
    );
  };
  return (
    <>
      <header id="header" className="header ">
        <div className="header-wrapper">
          <div id="top-bar" className="header-top hide-for-sticky nav-dark">
            <div className="flex-row container">
              <div className="flex-col hide-for-medium flex-left">
                <ul className="nav nav-left medium-nav-center nav-small  nav-divided">
                  <li className="html custom html_topbar_left">
                    HOTLINE: {PHONE_NUMBER}
                  </li>{" "}
                </ul>
              </div>

              <div className="flex-col hide-for-medium flex-center">
                <ul className="nav nav-center nav-small  nav-divided"></ul>
              </div>

              <div className="flex-col hide-for-medium flex-right">
                <ul className="nav top-bar-nav nav-right nav-small  nav-divided">
                  <li className="header-contact-wrapper">
                    <ul
                      id="header-contact"
                      className="nav nav-divided nav-uppercase header-contact"
                    >
                      <li className="">
                        {/* <a className="tooltip" title="06:00 - 21:00 "> */}
                        <i
                          className="bi bi-clock text-white"
                          style={{ fontSize: 16 }}
                        ></i>{" "}
                        <span className="text-white">06:00 - 23:00</span>
                        {/* </a> */}
                      </li>
                    </ul>
                  </li>{" "}
                </ul>
              </div>

              <div className="flex-col show-for-medium flex-grow">
                <ul className="nav nav-center nav-small mobile-nav  nav-divided">
                  <li className="html custom html_topbar_left">
                    GIẢM 10% KHI ĐẶT HOA HÔM NAY VÀ 20% ĐẶT HOA LẦN SAU
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
          <div
            id="masthead"
            className="header-main hide-for-sticky"
            style={{ height: 100 }}
          >
            <div
              className="header-inner flex-row container logo-left medium-logo-center"
              role="navigation"
            >
              {/* <!-- Logo --> */}
              <div id="logo" className="flex-col logo">
                {/* <!-- Header logo --> */}
                <a href="/">{WEB_NAME}</a>
              </div>

              {/* <!-- Mobile Left Elements --> */}
              <div className="flex-col show-for-medium flex-left">
                <ul className="mobile-nav nav nav-left ">
                  <li className="nav-icon has-icon">
                    <a
                      href="#"
                      data-open="#main-menu"
                      data-pos="left"
                      data-bg="main-menu-overlay"
                      data-color=""
                      className="is-small"
                      aria-label="Menu"
                      aria-controls="main-menu"
                      aria-expanded="false"
                      onClick={() => setOpenSideBar(true)}
                    >
                      <i className="bi bi-list"></i>
                    </a>
                  </li>{" "}
                </ul>
              </div>

              {/* <!-- Left Elements --> */}
              <div
                className="flex-col hide-for-medium flex-left
            flex-grow"
              >
                <ul className="header-nav header-nav-main nav nav-left  nav-uppercase"></ul>
              </div>

              {/* <!-- Right Elements --> */}
              <div className="flex-col hide-for-medium flex-right">
                <ul className="header-nav header-nav-main nav nav-right  nav-uppercase">
                  <li className="header-search-form search-form html relative has-icon">
                    <div className="header-search-form-wrapper">
                      <div className="searchform-wrapper ux-search-box relative is-normal">
                        <form
                          role="search"
                          method="get"
                          className="searchform"
                          // action="https://shophoahanoi.com/"
                        >
                          <div className="flex-row relative">
                            <div className="flex-col flex-grow">
                              <label
                                className="screen-reader-text"
                                htmlFor="woocommerce-product-search-field-0"
                              >
                                Tìm kiếm:
                              </label>
                              <input
                                type="search"
                                id="woocommerce-product-search-field-0"
                                className="search-field mb-0"
                                placeholder="Tìm kiếm sản phẩm..."
                                name="s"
                                autoComplete="off"
                              />
                              <input
                                type="hidden"
                                name="post_type"
                                value="product"
                              />
                            </div>
                            <div className="flex-col">
                              <button
                                type="submit"
                                value="Tìm kiếm"
                                className="ux-search-submit submit-button secondary button icon mb-0 btn btn-success"
                                aria-label="Submit"
                              >
                                <i className="bi bi-search"></i>{" "}
                              </button>
                            </div>
                          </div>
                          <div className="live-search-results text-left z-top">
                            <div
                              className="autocomplete-suggestions"
                              style={{
                                position: "absolute",
                                display: "none",
                                maxHeight: 300,
                                zIndex: 9999,
                              }}
                            ></div>
                          </div>
                        </form>
                      </div>{" "}
                    </div>
                  </li>{" "}
                </ul>
              </div>

              {/* <!-- Mobile Right Elements --> */}
              <div className="flex-col show-for-medium flex-right">
                <ul className="mobile-nav nav nav-right ">
                  <li className="cart-item has-icon">
                    <div className="header-button">
                      <button className="btn header-cart-link text-white">
                        <i
                          className="bi bi-basket"
                          data-icon-label={numberOrds}
                        ></i>
                      </button>{" "}
                    </div>

                    {/* <!-- Cart Sidebar Popup --> */}
                    <div
                      id="cart-popup"
                      className="mfp-hide widget_shopping_cart"
                    >
                      <div className="cart-popup-inner inner-padding">
                        <div className="cart-popup-title text-center">
                          <h4 className="uppercase">Giỏ hàng</h4>
                          <div className="is-divider"></div>
                        </div>
                        <div className="cart-sidebar-content relative"></div>{" "}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            id="wide-nav"
            className="header-bottom wide-nav hide-for-sticky flex-has-center hide-for-medium"
          >
            <div className="flex-row container">
              <div className="flex-col hide-for-medium flex-left">
                <ul className="nav header-nav header-bottom-nav nav-left  nav-divided nav-size-large nav-spacing-xlarge nav-uppercase">
                  <li className="header-vertical-menu" role="navigation">
                    <div className="header-vertical-menu__opener d-flex align-items-center">
                      <span className="header-vertical-menu__icon me-1">
                        <i className="bi bi-list"></i>{" "}
                      </span>
                      <span className="header-vertical-menu__title">
                        Danh mục sản phẩm{" "}
                      </span>
                      <i className="bi bi-chevron-down"></i>{" "}
                    </div>
                    <div className="header-vertical-menu__fly-out has-shadow">
                      <div className="menu-danh-muc-san-pham-container">
                        <ul
                          id="menu-danh-muc-san-pham"
                          className="ux-nav-vertical-menu nav-vertical-fly-out"
                        >
                          {categories.map((item) => (
                            <li
                              key={item.name}
                              className=" text-capitalize menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-design-default"
                            >
                              <a
                                href={`/danh-muc-san-pham/${item._id}`}
                                className="nav-top-link"
                              >
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>{" "}
                    </div>
                  </li>
                </ul>
              </div>

              <div className="flex-col hide-for-medium flex-center">
                <ul className="nav header-nav header-bottom-nav nav-center  nav-divided nav-size-large nav-spacing-xlarge nav-uppercase">
                  {categories?.map((item) => (
                    <li
                      key={item.name}
                      className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-3320 menu-item-design-default"
                    >
                      <a
                        href={`/danh-muc-san-pham/${item._id}`}
                        className="nav-top-link"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex-col hide-for-medium flex-right flex-grow">
                <ul className="nav header-nav header-bottom-nav nav-right  nav-divided nav-size-large nav-spacing-xlarge nav-uppercase">
                  {/* <li
                    className="account-item has-icon"
                  >
                    <button className="btn header-cart-link text-white">
                      Đăng nhập
                    </button>
                  </li> */}
                  <li className="cart-item has-icon has-dropdown">
                    <button
                      className="btn header-cart-link text-white"
                      onClick={() => {
                        window.location.href = ROUTES.PAYMENT;
                      }}
                    >
                      Giỏ hàng
                      <i
                        className="bi bi-basket ms-2 "
                        data-icon-label={numberOrds}
                      ></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="header-bg-container fill">
            <div className="header-bg-image fill"></div>
            <div className="header-bg-color fill"></div>
          </div>{" "}
        </div>
      </header>

      {opensideBar && renderSideBar()}
    </>
  );
}
