import React, { useState } from "react";
import {
  IOrderDto,
  IOrderLineItemDto,
  IOrderLocal,
} from "../../interface/product.interface";
import { ORDER_INIT } from "../../mocks/product";
import { LOCAL_STORAGE_KEY, MESSAGE, PHONE_REGEX } from "../../constants/general.constant";
import { formatPrice } from "../../helper/utils";
import { product } from "../../api";
import { toast } from "react-toastify";

/* eslint-disable jsx-a11y/anchor-is-valid */
interface IPropCheckoutForm {
  handleCompleteOrder: () => void;
}

const CheckoutForm = (props: IPropCheckoutForm) => {
  const { handleCompleteOrder } = props;

  const orderLines: IOrderLocal[] = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY.ORDERS) || "[]"
  );
  const [orderData, setOrderData] = useState<IOrderDto>(ORDER_INIT);

  const handleSubmitFormOrder = (e: any) => {
    e.preventDefault();
    if (!orderLines?.length) {
      toast.error(MESSAGE.ERROR_ORDER);
      return;
    }

    if (!PHONE_REGEX.test(orderData?.customerPhone)) {
      toast.error(MESSAGE.ERROR_PHONE_NUMBER);
      return;
    }

    const lineItems: IOrderLineItemDto[] = orderLines?.map((item) => ({
      product: item?.product,
      quantity: item?.quantity,
      option: "",
      note: "",
    }));

    const param = {
      ...orderData,
      lineItems,
    };

    product
      .createOrder(param)
      .then((res) => {
        toast.success(MESSAGE.SUCCESS_ORDER);
        setOrderData(ORDER_INIT);
        localStorage.removeItem(LOCAL_STORAGE_KEY.ORDERS);
        handleCompleteOrder();
      })
      .catch((err) => {
        toast.error(MESSAGE.ERROR_ORDER);
      });
  };

  return (
    <>
      <div className="cart-container container page-wrapper page-checkout">
        <div className="woocommerce">
          <div className="woocommerce-notices-wrapper"></div>
          <div className="woocommerce-form-coupon-toggle">
            <div className="woocommerce-info message-wrapper">
              <div className="message-container container medium-text-center">
                Bạn có mã ưu đãi?{" "}
                <a href="#" className="showcoupon">
                  Ấn vào đây để nhập mã
                </a>{" "}
              </div>
            </div>
          </div>

          <form
            className="checkout_coupon woocommerce-form-coupon has-border is-dashed"
            method="post"
            style={{ display: "none" }}
          >
            <p>Nếu bạn có mã giảm giá, vui lòng điền vào phía bên dưới.</p>
            <div className="coupon">
              <div className="flex-row medium-flex-wrap">
                <div className="flex-col flex-grow">
                  <input
                    type="text"
                    name="coupon_code"
                    className="input-text"
                    placeholder="Mã ưu đãi"
                    id="coupon_code"
                  />
                </div>
                <div className="flex-col">
                  <button
                    type="submit"
                    className="button expand"
                    name="apply_coupon"
                    value="Áp dụng"
                  >
                    Áp dụng
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="woocommerce-notices-wrapper"></div>
          <form
            name="checkout"
            action=""
            onSubmit={handleSubmitFormOrder}
            className="checkout woocommerce-checkout "
          >
            <div className="row pt-0 ">
              <div className="large-7 col  ">
                <div id="customer_details">
                  <div className="clear">
                    <div className="woocommerce-billing-fields">
                      <h3>Thông tin thanh toán</h3>

                      <div className="woocommerce-billing-fields__field-wrapper">
                        <p
                          className="form-row form-row-wide"
                          id="customer_name_field"
                          data-priority="30"
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-input fl-is-active">
                              <label
                                htmlFor="customer_name"
                                className="fl-label"
                              >
                                Tên khách hàng&nbsp;
                                <abbr className="required" title="bắt buộc">
                                  *
                                </abbr>
                              </label>
                              <input
                                type="text"
                                className="input-text fl-input"
                                name="customer_name"
                                id="customer_name"
                                placeholder="Tên khách hàng"
                                required
                                value={orderData?.customerName}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    customerName: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </span>
                        </p>
                        <p
                          className="form-row form-row-wide"
                          id="billing_company_field"
                          data-priority="30"
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-input fl-is-active">
                              <label
                                htmlFor="billing_company"
                                className="fl-label"
                              >
                                Tên công ty
                              </label>
                              <input
                                type="text"
                                className="input-text fl-input"
                                name="billing_company"
                                id="billing_company"
                                placeholder="Tên công ty"
                                value={orderData?.customerCompany}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    customerCompany: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </span>
                        </p>

                        <p
                          className="form-row form-row-wide"
                          id="customer_address_field"
                          data-priority="30"
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-input fl-is-active">
                              <label
                                htmlFor="customer_address"
                                className="fl-label"
                              >
                                Địa chỉ&nbsp;
                                <abbr className="required" title="bắt buộc">
                                  *
                                </abbr>
                              </label>
                              <input
                                type="text"
                                className="input-text fl-input"
                                name="customer_address"
                                id="customer_address"
                                placeholder="Địa chỉ"
                                required
                                value={orderData?.customerAddress}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    customerAddress: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </span>
                        </p>

                        <p
                          className="form-row address-field validate-required form-row-wide"
                          id="billing_city_field"
                          data-priority="70"
                          data-o_className="form-row form-row-wide address-field validate-required"
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-input fl-is-active">
                              <label
                                htmlFor="billing_city"
                                className="fl-label"
                              >
                                Tỉnh / Thành phố&nbsp;
                                <abbr className="required" title="bắt buộc">
                                  *
                                </abbr>
                              </label>
                              <input
                                type="text"
                                className="input-text fl-input"
                                name="billing_city"
                                id="billing_city"
                                placeholder="Tỉnh / Thành phố"
                                required
                                value={orderData?.customerCity}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    customerCity: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </span>
                        </p>

                        <p
                          className="form-row address-field validate-postcode form-row-wide"
                          id="billing_postcode_field"
                          data-priority="65"
                          data-o_className="form-row form-row-wide address-field validate-postcode"
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-input fl-is-active">
                              <label
                                htmlFor="billing_postcode"
                                className="fl-label"
                              >
                                Mã bưu điện&nbsp;
                                <span className="optional">(tuỳ chọn)</span>
                              </label>
                              <input
                                type="text"
                                className="input-text fl-input"
                                name="billing_postcode"
                                id="billing_postcode"
                                placeholder="Mã bưu điện"
                                value={orderData?.zip}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    zip: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </span>
                        </p>

                        <p
                          className="form-row form-row-wide validate-required validate-phone"
                          id="billing_phone_field"
                          data-priority="100"
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-input fl-is-active">
                              <label
                                htmlFor="billing_phone"
                                className="fl-label"
                              >
                                Số điện thoại&nbsp;
                                <abbr className="required" title="bắt buộc">
                                  *
                                </abbr>
                              </label>
                              <input
                                type="tel"
                                className="input-text fl-input"
                                name="billing_phone"
                                id="billing_phone"
                                placeholder="Số điện thoại"
                                required
                                value={orderData?.customerPhone}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    customerPhone: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </span>
                        </p>

                        <p
                          className="form-row form-row-wide validate-required validate-email"
                          id="billing_email_field"
                          data-priority="110"
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-input fl-is-active">
                              <label
                                htmlFor="billing_email"
                                className="fl-label"
                              >
                                Địa chỉ email
                              </label>
                              <input
                                type="email"
                                className="input-text fl-input"
                                name="billing_email"
                                id="billing_email"
                                placeholder="Địa chỉ email"
                                required
                                value={orderData?.customerEmail}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    customerEmail: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="clear">
                    <div className="woocommerce-shipping-fields"></div>
                    <div className="woocommerce-additional-fields">
                      <h3>Thông tin bổ sung</h3>

                      <div className="woocommerce-additional-fields__field-wrapper">
                        <p
                          className="form-row notes"
                          id="order_comments_field"
                          data-priority=""
                        >
                          <span className="woocommerce-input-wrapper">
                            <div className="fl-wrap fl-wrap-textarea">
                              <label
                                htmlFor="order_comments"
                                className="fl-label"
                              >
                                Ghi chú về đơn hàng, ví dụ: thời gian hay chỉ
                                dẫn địa điểm giao hàng chi tiết hơn.
                              </label>
                              <textarea
                                name="order_comments"
                                className="input-text fl-textarea"
                                id="order_comments"
                                placeholder="Ghi chú về đơn hàng, ví dụ: thời gian hay chỉ dẫn địa điểm giao hàng chi tiết hơn."
                                rows={2}
                                cols={5}
                                value={orderData?.orderNote}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    orderNote: e.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </span>
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="large-5 col">
                <div className="is-sticky-column">
                  <div
                    className="is-sticky-column__inner"
                    style={{
                      position: "relative",
                      transform: "translate3d(0px, 0px, 0px)",
                    }}
                  >
                    <div className="col-inner has-border">
                      <div className="checkout-sidebar sm-touch-scroll">
                        <h3 id="order_review_heading">Đơn hàng của bạn</h3>
                        <div
                          id="order_review"
                          className="woocommerce-checkout-review-order"
                        >
                          <table className="shop_table woocommerce-checkout-review-order-table">
                            <thead>
                              <tr>
                                <th className="product-name">Sản phẩm</th>
                                <th className="product-total">Tạm tính</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderLines?.map((item: IOrderLocal) => (
                                <tr className="cart_item">
                                  <td className="product-name">
                                    {item?.name}&nbsp;{" "}
                                    <strong className="product-quantity">
                                      ×&nbsp;{item?.quantity}
                                    </strong>{" "}
                                  </td>
                                  <td className="product-total">
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          ₫
                                        </span>
                                        &nbsp;
                                        {formatPrice(
                                          item?.price * item?.quantity
                                        )}
                                      </bdi>
                                    </span>{" "}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr className="order-total">
                                <th>Tổng</th>
                                <td>
                                  <strong>
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          ₫
                                        </span>
                                        &nbsp;{" "}
                                        {formatPrice(
                                          orderLines.reduce(
                                            (acc: any, crr: any) =>
                                              acc + crr?.quantity * crr?.price,
                                            0
                                          )
                                        )}
                                      </bdi>
                                    </span>
                                  </strong>{" "}
                                </td>
                              </tr>
                            </tfoot>
                          </table>

                          <div
                            id="payment"
                            className="woocommerce-checkout-payment"
                          >
                            <ul className="wc_payment_methods payment_methods methods ps-0">
                              <li>
                                <div className="woocommerce-info message-wrapper">
                                  <div className="message-container container medium-text-center">
                                    Không có hình thức thanh toán nào được thiết
                                    lập theo địa chỉ khu vực của bạn. Vui lòng
                                    liên hệ với quản trị website để hỗ trợ vấn
                                    đề này.{" "}
                                  </div>
                                </div>
                              </li>{" "}
                            </ul>
                            <div className="form-row place-order">
                              <noscript>
                                Trình duyệt của bạn không hỗ trợ JavaScript,
                                hoặc nó bị vô hiệu hóa, hãy đảm bảo bạn nhấp vào{" "}
                                <em>Cập nhật giỏ hàng</em> trước khi bạn thanh
                                toán. Bạn có thể phải trả nhiều hơn số tiền đã
                                nói ở trên, nếu bạn không làm như vậy. <br />
                                <button
                                  type="submit"
                                  className="button alt"
                                  name="woocommerce_checkout_update_totals"
                                  value="Cập nhật tổng"
                                >
                                  Cập nhật tổng
                                </button>
                              </noscript>
                              <div className="woocommerce-terms-and-conditions-wrapper"></div>
                              <button
                                type="submit"
                                className="button alt"
                                name="woocommerce_checkout_place_order"
                                id="place_order"
                                value="Đặt hàng"
                                data-value="Đặt hàng"
                              >
                                Đặt hàng
                              </button>
                              <input
                                type="hidden"
                                id="woocommerce-process-checkout-nonce"
                                name="woocommerce-process-checkout-nonce"
                                value="10bbc50a00"
                              />
                              <input
                                type="hidden"
                                name="_wp_http_referer"
                                value="/?wc-ajax=update_order_review"
                              />{" "}
                            </div>
                          </div>
                        </div>
                        <div className="woocommerce-privacy-policy-text">
                          <p>
                            Thông tin cá nhân của bạn sẽ được sử dụng để xử lý
                            đơn hàng, tăng trải nghiệm sử dụng website, và cho
                            các mục đích cụ thể khác đã được mô tả trong{" "}
                            <a
                              href="#"
                              className="woocommerce-privacy-policy-link"
                              target="_blank"
                            >
                              chính sách riêng tư
                            </a>
                            .
                          </p>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default React.memo(CheckoutForm);
