import { useEffect, useState } from "react";
import { blog } from "../../api";
import SliderCoffee from "../../components/Slider";
import Content from "./HomeContent";
import { IBlogs } from "../../interface/blogs.interface";
import moment from "moment";
import { Link } from "react-router-dom";
export default function Home() {
  const [listBlog, setListBlog] = useState<IBlogs[]>([]);
  useEffect(() => {
    getApiAllBlog();
  }, []);

  const getApiAllBlog = () => {
    blog.getAllBlog().then((res) => {
      setListBlog(res?.data?.data);
    });
  };

  return (
    <>
      <div className="row row-small">
        <div className="col-lg-8 col-sm-12">
          <SliderCoffee />
        </div>
        <div className="col-lg-4 col-sm-0 hide-for-small">
          <div className="blog-box">
            <p className="blog-title text-center m-0">Những bài viết ý nghĩa</p>
            <div className="blog-container">
              {listBlog?.map((item) => (
                <Link key={item._id} to={item.slug}>
                  <div className="box box-vertical box-text-bottom box-blog-post has-hover">
                    <div
                      className="box-image  align-items-center"
                      style={{ width: "25%", margin: "auto" }}
                    >
                      <div className="image-cover">
                        <img
                          className="attachment-original size-original wp-post-image"
                          src={item.thumbnailImgUrl}
                          alt="hoa sen vanguoi dep"
                        />{" "}
                      </div>
                    </div>
                    <div className="box-text text-left is-small">
                      <div className="box-text-inner blog-post-inner">
                        <h5 className="post-title is-large ">{item.title}</h5>
                        <div className="post-meta is-small op-8">
                          {moment(item.createdDate).format("DD/MM/YYYY")}
                        </div>{" "}
                        <div className="is-divider"></div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      <section className="hide-for-large">
        <div className="row row-small">
          <div className="col-lg-12">
            <div className="blog-box">
              <p className="blog-title text-center m-0">
                Những bài viết ý nghĩa
              </p>
              <div className="blog-container">
                {listBlog?.map((item) => (
                  <Link key={item._id} to={item.slug}>
                    <div className="box box-vertical box-text-bottom box-blog-post has-hover">
                      <div
                        className="box-image  align-items-center"
                        style={{ width: "25%", margin: "auto" }}
                      >
                        <div className="image-cover">
                          <img
                            className="attachment-original size-original wp-post-image"
                            src={item.thumbnailImgUrl}
                            alt="hoa sen vanguoi dep"
                          />{" "}
                        </div>
                      </div>
                      <div className="box-text text-left is-small">
                        <div className="box-text-inner blog-post-inner">
                          <h5 className="post-title is-large ">{item.title}</h5>
                          <div className="post-meta is-small op-8">
                            {moment(item.createdDate).format("DD/MM/YYYY")}
                          </div>{" "}
                          <div className="is-divider"></div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Content />
    </>
  );
}
