import numeral from "numeral";

export function formatValue(value: number | string, format: string) {
  return numeral(value).format(format);
}

export function formatPrice(value: number) {
  return value?.toLocaleString("de-DE", { maximumFractionDigits: 2 });
}

export const formatTime = (time: string) => {
  if (time) {
    const hour = time.split(":")[0];
    const minute = time.split(":")[1];
    return Number(hour) * 60 + Number(minute);
  }
  return 0;
};

export const convertNumber = (value: any) => {
  const tmpValue = value?.toString().replaceAll(",", "");
  if (tmpValue && !isNaN(Number(tmpValue))) {
    return Number(tmpValue);
  }
  return 0;
};

export const getBaseUrlApi = () => {
  const baseUrl: any = window.globalThis;
  return baseUrl.apiUrl;
};

export const getElementById = (idName: string) => {
  return document.getElementById(idName);
};

export const shortenAddress = (str: string) => {
  if (str.length < 10) return str;
  return `${str.slice(0, 11)}...${str.slice(str.length - 11)}`;
};

export const isIntegerStr = (str: string) => {
  var n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
};
