/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import "./App.css";
import RouterDom from "./Router";

import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import qrZalo from "./assets/images/zalo-QR.jpg";
import { PHONE_NUMBER } from "./constants/general.constant";

const App = () => {
  return (
    <>
      <div className="site-main">
        <RouterDom />
      </div>
      <div className="contact-social">
        <div className="zaloFt contactTus">
          <div className="noteSocial">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href={`https://zalo.me/${PHONE_NUMBER}`} target="_blank">
                <img src="https://shophoahanoi.com/wp-content/plugins/zalo-and-hotline/assets/images/zalo.png" />
              </a>
            </div>
          </div>
          <div className="zalo-bar barr">{PHONE_NUMBER}</div>
          <div className="zalo-qr">
            <img width={300} src={qrZalo} alt="qr-zalo" />
          </div>
        </div>
        <div className="phoneFt contactTus">
          <div className="noteSocial">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href={`tel:${PHONE_NUMBER}`}>
                <img src="https://shophoahanoi.com/wp-content/plugins/zalo-and-hotline/assets/images/phone.png" />
              </a>
            </div>
          </div>
          <div className="phone-bar barr">{PHONE_NUMBER}</div>
        </div>
      </div>
    </>
  );
};

export default App;
