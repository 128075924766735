export default function BlockWidget() {
  return (
    <aside id="block_widget-2" className="widget block_widget">
      <div className="row">
        <div className="small-12 large-12">
          <div className="col-inner">
            <div className="box has-hover   has-hover box-vertical box-text-bottom">
              <div className="box-image" style={{ width: "21%" }}>
                <div className="">
                  <img
                    width="75"
                    height="75"
                    src="https://shophoahanoi.com/wp-content/uploads/2022/10/fast-delivery.png"
                    className="attachment-original size-original"
                    alt="shop hoa tươi hà nội"
                    decoding="async"
                    loading="lazy"
                  />{" "}
                </div>
              </div>

              <div className="box-text text-center">
                <div className="box-text-inner">
                  <div className="text">
                    <p>
                      <span style={{ fontSize: "90%" }}>
                        <strong>GIAO NHANH TRONG 60 PHÚT</strong>
                      </span>
                      <br></br>{" "}
                      <span style={{ fontSize: "85%" }}>
                        <em>60 minutes quick delivery</em>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="box has-hover has-hover box-vertical box-text-bottom">
              <div className="box-image" style={{ width: "21%" }}>
                <div className="">
                  <img
                    width="75"
                    height="75"
                    src="https://shophoahanoi.com/wp-content/uploads/2022/10/medal-1.png"
                    className="attachment-original size-original"
                    alt="shop hoa tươi hà nội"
                    decoding="async"
                    loading="lazy"
                  />{" "}
                </div>
              </div>

              <div className="box-text text-center">
                <div className="box-text-inner">
                  <div className="text">
                    <p>
                      <span style={{ fontSize: "90%" }}>
                        <strong>&nbsp;TẶNG THIỆP CAO CẤP</strong>
                      </span>
                      <br></br>{" "}
                      <span style={{ fontSize: "85%" }}>
                        <em>Free greeting cards</em>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="box has-hover   has-hover box-vertical box-text-bottom">
              <div className="box-image" style={{ width: "21%" }}>
                <div className="">
                  <img
                    width="75"
                    height="75"
                    src="https://shophoahanoi.com/wp-content/uploads/2022/10/recommended-1.png"
                    className="attachment-original size-original"
                    alt="shop hoa tươi hà nội"
                    decoding="async"
                    loading="lazy"
                  />{" "}
                </div>
              </div>

              <div className="box-text text-center">
                <div className="box-text-inner">
                  <div className="text">
                    <p>
                      <span style={{ fontSize: "90%" }}>
                        <strong>CAM KẾT HÀI LÒNG 100%</strong>
                      </span>
                      <br></br>{" "}
                      <span style={{ fontSize: "85%" }}>
                        <em>100% guarantee smile</em>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="box has-hover   has-hover box-vertical box-text-bottom">
              <div className="box-image" style={{ width: "21%" }}>
                <div className="">
                  <img
                    width="75"
                    height="75"
                    src="https://shophoahanoi.com/wp-content/uploads/2022/10/free-delivery-1.png"
                    className="attachment-original size-original"
                    alt="shop hoa tươi hà nội"
                    decoding="async"
                    loading="lazy"
                  />{" "}
                </div>
              </div>

              <div className="box-text text-center">
                <div className="box-text-inner">
                  <div className="text">
                    <p>
                      <span style={{ fontSize: "90%" }}>
                        <strong>MIỄN PHÍ GIAO HÀNG 63 TỈNH</strong>
                      </span>
                      <br></br>{" "}
                      <span style={{ fontSize: "85%" }}>
                        <em>Free shipping (nội thành)</em>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
