import API from "./config.api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postBlog(param: any) {
    return API.post(`admin/articles`, param);
  },

  getAllBlog() {
    return API.get(`articles`);
  },

  deleteBlog(id: string) {
    return API.delete(`admin/articles/${id}`);
  },

  getBlogBySlug(slug: string) {
    return API.get(`articles/${slug}`);
  },
};
